import React from 'react';
import {
  Box, Stack, SxProps, Theme, Typography,
} from '@mui/material';
import { OutputData } from '@editorjs/editorjs';
import { ParsedEditorData } from 'components';
import useEditor from 'hooks/use-editor';

interface IProps{
    defaultData: OutputData,
    label: string,
    isExpandedVersion: boolean,
    onChange: (data: OutputData) => void,
    onReady: () => void;
}

const EditorJsEditor: React.FC<IProps> = ({
  defaultData, label, isExpandedVersion, onChange, onReady,
}) => {
  const EDITOR_HOLDER_ID = 'editorjs';
  const EDITOR_MIN_HEIGHT = 80;
  const EDITOR_MIN_HEIGHT_PX = `${EDITOR_MIN_HEIGHT + 40}px`;

  const { editorData } = useEditor(EDITOR_HOLDER_ID, defaultData, onChange, onReady, EDITOR_MIN_HEIGHT);

  const editorStyle: SxProps<Theme> = {
    borderColor: (theme: Theme) => theme.palette.primary.main,
    minHeight: EDITOR_MIN_HEIGHT_PX,
    boxShadow: (theme: Theme) => theme.shadows[5],
    p: 2,
    overflowWrap: 'break-word',
    maxHeight: 'inherit',
  };

  return (
    <Stack
      direction={isExpandedVersion ? 'row' : 'column'}
      spacing={5}
      justifyContent="center"
      sx={{ width: '100%' }}
    >
      <Stack direction="column" spacing={2} sx={{ width: isExpandedVersion ? '45%' : '100%' }}>
        <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
          {label || 'Editor'}
        </Typography>

        <Box
          id={EDITOR_HOLDER_ID}
          sx={{
            ...editorStyle,
          }}
        />
      </Stack>
      {isExpandedVersion && (
        <Stack direction="column" spacing={2} sx={{ width: '45%' }}>
          <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.secondary }}>
            Preview
          </Typography>
          <ParsedEditorData editorData={editorData} style={editorStyle} />
        </Stack>
      )}
    </Stack>
  );
};

export default React.memo(EditorJsEditor);
