import { Button } from '@mui/material';
import AppModal from 'components/app-modal/AppModal';
import VideoPlayer from 'components/video-player/VideoPlayer';
import React, { CSSProperties, useEffect, useState } from 'react';
import PreviousIcon from '@mui/icons-material/ArrowBack';
import NextIcon from '@mui/icons-material/ArrowForward';
import KeyboardKeys from 'enums/keyboard-keys';
import LoadingBox from 'components/loading-box/LoadingBox';

// eslint-disable-next-line no-shadow
export enum MediaType{
    Video,
    Image
}

export interface MediaModalEntry{
    attachmentId: string,
    name:string
    source:string;
    type:MediaType
}

interface IProps{
    mediaEntries: MediaModalEntry[]
    open: boolean;
    selectedIndex?: number;
    onClose?: () => void;
}

const MediaModal: React.FC<IProps> = ({
  mediaEntries, open, selectedIndex, onClose,
}) => {
  if (mediaEntries.length === 0) throw new Error('No media entries passed.');

  const [activeIndex, setActiveIndex] = useState<number>(selectedIndex ?? 0);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const activeEntry = mediaEntries[activeIndex];

  useEffect(() => {
    if (selectedIndex === undefined || selectedIndex === activeIndex) {
      return;
    }

    setActiveIndex(selectedIndex);
  }, [selectedIndex]);

  useEffect(() => {
    setIsImageLoading(true);
  }, [activeIndex]);

  const determineModalTitle = (): string => {
    switch (activeEntry.type) {
      case MediaType.Video:
        return `Video: ${activeEntry.name}`;
      case MediaType.Image:
        return `Image: ${activeEntry.name}`;
      default:
        throw new Error('Invalid media type');
    }
  };

  const mediaObjectStyle: CSSProperties = { objectFit: 'contain', width: '100%', height: '100%' };

  const imageLoaded = (attachmentId: string) => {
    if (attachmentId === activeEntry.attachmentId) {
      setIsImageLoading(false);
    }
  };

  const renderModalContent = (): React.ReactElement => {
    switch (activeEntry.type) {
      case MediaType.Video:
        return <VideoPlayer src={activeEntry.source} attachmentId={activeEntry.attachmentId} />;
      case MediaType.Image:
        return (
          <>
            {isImageLoading && <LoadingBox />}

            <img
              src={activeEntry.source}
              alt={activeEntry.name}
              style={{ ...mediaObjectStyle, display: isImageLoading ? 'none' : 'inherit' }}
              onLoad={() => imageLoaded(activeEntry.attachmentId)}
            />
          </>
        );
      default:
        throw new Error('Invalid media type');
    }
  };

  const showPrevious = () => {
    setActiveIndex(activeIndex === 0 ? mediaEntries.length - 1 : activeIndex - 1);
  };

  const showNext = () => {
    setActiveIndex(activeIndex === mediaEntries.length - 1 ? 0 : activeIndex + 1);
  };

  const keyupListener = (keyupEvent: KeyboardEvent) => {
    const { key } = keyupEvent;
    if (mediaEntries.length === 1) return;
    if (key === KeyboardKeys.ArrowLeft) showPrevious();
    if (key === KeyboardKeys.ArrowRight) showNext();
  };

  useEffect(() => {
    window.addEventListener('keyup', keyupListener);

    return () => {
      window.removeEventListener('keyup', keyupListener);
    };
  }, [activeIndex]);

  const renderNextPreviousButtons = ():React.ReactElement => (
    mediaEntries.length === 1 ? <> </> : (
      <>
        <Button
          variant="contained"
          onClick={() => showPrevious()}
        >
          <PreviousIcon />
        </Button>
        <Button
          variant="contained"
          onClick={() => showNext()}
        >
          <NextIcon />
        </Button>
      </>
    )
  );

  return (

    <AppModal
      title={determineModalTitle()}
      open={open}
      onClose={() => (onClose ? onClose() : {})}
      dark
      contentSx={{
        height: '0', width: '90%', padding: 2, alignSelf: 'center', justifyContent: 'center',
      }}
      renderActionButtons={() => renderNextPreviousButtons()}
    >
      {renderModalContent()}
    </AppModal>

  );
};

export default MediaModal;
