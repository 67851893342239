import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';

interface IProps {
    color?:
        | 'secondary'
        | 'inherit'
        | 'default'
        | 'primary'
        | 'success'
        | 'error'
        | 'info'
        | 'warning'
        | undefined;
    size?: 'medium' | 'small' | 'large' | undefined;
    icon?: JSX.Element;
    onClick?: () => void;
}

const PageButton: React.FC<IProps> = ({
  color = 'secondary',
  icon = <AddIcon />,
  size = 'medium',
  onClick,
}) => (
  <Fab
    color={color}
    size={size}
    sx={{
      position: 'fixed',
      bottom: '45px',
      right: '20px',
      zIndex: 10,
    }}
    onClick={onClick}
  >
    {icon}
  </Fab>
);

export default PageButton;
