import { JOURNALS_ROUTE_COLLECTION } from 'journals-constants';
import { JournalsRoute } from '../types/routing';

/**
 * Returns the path with the value replacing the template variable.
 * @param template The path which may include template params ({0}).
 * @param value The value to replace {0}.
 * @returns The parsed path with the value replacing {0}.
 */
const getParsedTemplate = (template: string, value: string): string => `${template.replace(':{0}', value)}`;

/**
 * Returns the path for a route with a specific object.
 * @param route The route (e.g. EditJournal).
 * @param id The object ID.
 * @returns The path result (e.g. journals/journalId).
 */
const generateRouteWithId = (route: JournalsRoute, id: string): string => getParsedTemplate(route.path, id);

/**
 * Returns the route matched by the passed path.
 * @param path The path.
 * @returns The route.
 */
const getParsedMatchedRouteDetails = (path: string): JournalsRoute => {
  let simplifiedPath = path;
  let pathItemId;
  if (path.match(/.*\/[a-z0-9-]{36}$/g)) {
    // ends with guid.
    const lastPartIndex = path.lastIndexOf('/');
    simplifiedPath = path.substring(0, lastPartIndex + 1);
    pathItemId = path.substring(lastPartIndex + 1);
  }

  const routeDetailList = Object.keys(JOURNALS_ROUTE_COLLECTION)
    .map((key) => ({
      routeName: key,
      path: JOURNALS_ROUTE_COLLECTION[key].path,
      title: JOURNALS_ROUTE_COLLECTION[key].title,
    }))
    .filter((route) => route.path.replace(/:{\d}/g, '') === simplifiedPath);

  if (routeDetailList.length > 0) {
    const chosenRoute = JOURNALS_ROUTE_COLLECTION[routeDetailList[0].routeName];
    if (pathItemId == null) return chosenRoute;

    const parsedRouteDetails = {
      path: chosenRoute.path.replace(':{0}', pathItemId),
      title: chosenRoute.title,
    };

    return parsedRouteDetails;
  }

  return JOURNALS_ROUTE_COLLECTION.Home;
};

export { getParsedMatchedRouteDetails, generateRouteWithId };
