import moment from 'moment';
import { DEFAULT_FILTER } from '../../constants';
import { IEntriesFilter, IFilterChip } from '../../types';
import { IDateRange } from '../../types/entries-filter';

interface FilterSetFunctions{
  setTags: (tags:Array<string>) => void
}

const getInitialFilter = (inputFilter?: IEntriesFilter) => {
  const initialFilter = { ...DEFAULT_FILTER, ...inputFilter };
  return initialFilter;
};

const parseRangeDate = (month:string):{month:string, year:number} => {
  const [m, y] = month.split(':');
  return { month: m, year: Number(y) };
};

const getInitialDateRange = (minMonth: string|null, maxMonth:string|null): IDateRange => {
  const { dateRange } = DEFAULT_FILTER;
  if (minMonth) {
    const rangeDate = parseRangeDate(minMonth);
    dateRange.minMonth = rangeDate.month;
    dateRange.minYear = rangeDate.year;
  }
  if (maxMonth) {
    const rangeDate = parseRangeDate(maxMonth);
    dateRange.maxMonth = rangeDate.month;
    dateRange.maxYear = rangeDate.year;
  }
  return dateRange;
};

const serializeMinDateRange = (dateRange: IDateRange): string => `${dateRange.minYear}:${dateRange.minMonth}`;
const serializeMaxDateRange = (dateRange: IDateRange): string => `${dateRange.maxYear}:${dateRange.maxMonth}`;

const removeMinFromDateRange = (dateRange:IDateRange): IDateRange => ({
  ...dateRange,
  minMonth: DEFAULT_FILTER.dateRange.minMonth,
  minYear: DEFAULT_FILTER.dateRange.minYear,
});

const removeMaxFromDateRange = (dateRange:IDateRange): IDateRange => ({
  ...dateRange,
  maxMonth: DEFAULT_FILTER.dateRange.maxMonth,
  maxYear: DEFAULT_FILTER.dateRange.maxYear,
});

const getUpdatedFilterChips = (newFilter:IEntriesFilter, journals:Array<any>, setFunctions: FilterSetFunctions)
    : Array<IFilterChip> => {
  const filterChips:Array<IFilterChip> = [];

  if (newFilter.searchText !== undefined && newFilter.searchText !== DEFAULT_FILTER.searchText) {
    filterChips.push({
      field: 'searchText',
      label: `Search Text${newFilter.searchInContent ? ' (inclusive)' : ''}`,
      value: newFilter.searchText,
      getUpdatedFilterOnDelete: (filter) => ({ ...filter, searchText: DEFAULT_FILTER.searchText }),
    });
  }

  if (newFilter.journalIds !== undefined && newFilter.journalIds !== DEFAULT_FILTER.journalIds) {
    const selectedJournals = newFilter.journalIds
      .split(',')
      .map((jid:string) => journals.find((j) => j.id === jid));
    filterChips.push({
      field: 'journalIds',
      label: 'Journals',
      value: selectedJournals.map((j:any) => j.title).join(', '),
      getUpdatedFilterOnDelete: (filter) => ({ ...filter, journalIds: DEFAULT_FILTER.journalIds }),
    });
  }

  if (newFilter.tags !== undefined && newFilter.tags !== DEFAULT_FILTER.tags) {
    filterChips.push({
      field: 'tags',
      label: 'Tags',
      value: newFilter.tags.split(',').join(', '),
      getUpdatedFilterOnDelete: (filter) => ({ ...filter, tags: DEFAULT_FILTER.tags }),
      setFn: () => setFunctions.setTags([]),
    });
  }

  if (serializeMinDateRange(newFilter.dateRange) !== serializeMinDateRange(DEFAULT_FILTER.dateRange)) {
    filterChips.push({
      field: 'minMonth',
      label: 'From',
      value: moment(serializeMinDateRange(newFilter.dateRange), 'YYYY:MM').format('MMMM YYYY'),
      getUpdatedFilterOnDelete: (filter: IEntriesFilter) => ({
        ...filter,
        dateRange: removeMinFromDateRange(filter.dateRange),
      }),
    });
  }

  if (serializeMaxDateRange(newFilter.dateRange) !== serializeMaxDateRange(DEFAULT_FILTER.dateRange)) {
    filterChips.push({
      field: 'maxMonth',
      label: 'To',
      value: moment(serializeMaxDateRange(newFilter.dateRange), 'YYYY:MM').format('MMMM YYYY'),
      getUpdatedFilterOnDelete: (filter: IEntriesFilter) => ({
        ...filter,
        dateRange: removeMaxFromDateRange(filter.dateRange),
      }),
    });
  }

  return filterChips;
};

const addFilterToQuery = (query: string, key:string, value:string|undefined): string => {
  if (value === undefined || value === '') { return query; }
  let updatedQuery = query;
  if (updatedQuery !== '') updatedQuery += '&';
  updatedQuery += `${key}=${value}`;
  return updatedQuery;
};

const getFilterQueryString = (filter: IEntriesFilter): string => {
  let query = addFilterToQuery('', 'journalIds', filter.journalIds);
  if (filter.dateRange !== undefined) {
    query = addFilterToQuery(query, 'minMonth', `${filter.dateRange.minYear}:${filter.dateRange.minMonth}`);
  }
  if (filter.dateRange !== undefined) {
    query = addFilterToQuery(query, 'maxMonth', `${filter.dateRange.maxYear}:${filter.dateRange.maxMonth}`);
  }
  query = addFilterToQuery(query, 'tags', filter.tags);
  query = addFilterToQuery(query, 'searchText', filter.searchText);
  query = addFilterToQuery(query, 'searchInContent', filter.searchInContent?.toString());
  return query;
};

export {
  getInitialFilter, getUpdatedFilterChips, getFilterQueryString, getInitialDateRange,
};
