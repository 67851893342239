import AttachmentIcon from '@mui/icons-material/Attachment';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import OpenIcon from '@mui/icons-material/OpenInNew';
import VideoIcon from '@mui/icons-material/VideoFile';
import {
  Avatar,
  IconButton, ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText, Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import { ApiAttachment } from 'types/api';

  interface IProps{
    attachment: ApiAttachment;
    isEditMode: boolean;
    onClick: () => void;
    onDelete?: () => void;
  }

const AttachmentListItem: React.FC<IProps> = ({
  attachment, isEditMode, onClick, onDelete,
}) => (
  <ListItem
    onClick={() => (onClick ? onClick() : {})}
    sx={{ p: 0 }}
    secondaryAction={
                                    isEditMode && (
                                    <IconButton onClick={() => (onDelete ? onDelete() : {})}>
                                      <DeleteIcon color="error" />
                                    </IconButton>
                                    )
                                }
  >
    <ListItemButton>
      <ListItemAvatar onClick={() => (onClick ? onClick() : {})}>
        <Avatar>
          {attachment.type === 'Image' && <ImageIcon />}
          {attachment.type === 'Video' && <VideoIcon />}
          {attachment.type === 'File' && <AttachmentIcon />}
        </Avatar>
      </ListItemAvatar>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <ListItemText
          primary={<Typography variant="body2">{attachment.name}</Typography>}
          onClick={() => (onClick ? onClick() : {})}
        />
        {!isEditMode && (
          <OpenIcon sx={{ ml: 1 }} onClick={() => (onClick ? onClick() : {})} />
        )}
      </Stack>
    </ListItemButton>
  </ListItem>
);

export default AttachmentListItem;
