import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Auth0Provider
    domain="journal-ba.eu.auth0.com"
    clientId="xZqvIT3DuHOwIFS4K79sLKLyTd0i13N3"
    redirectUri={window.location.origin}
    audience="https://api.journal.unclebenportal.net"
    scope="super"
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
