import {
  Chip,
  Container,
  Hidden,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import AttachmentIcon from '@mui/icons-material/Attachment';
import TagIcon from '@mui/icons-material/Bookmark';
import DescriptionIcon from '@mui/icons-material/TextSnippet';
import { useSearchParams } from 'react-router-dom';
import { DataList, EntryDetailsModal, JournalAvatar } from 'components';
import usePermissions from 'hooks/use-permissions';
import useApiFetch from 'hooks/use-api-fetch';
import { JOURNALS_ROUTE_COLLECTION } from 'journals-constants';
import { getApiUrl } from 'utils/api-utils';
import { ApiEndpoint } from 'enums/api';
import { IEntriesFilter } from '../../types';
import { ApiEntriesFilterResponse, ApiJournalEntry } from '../../../../types/api';
import { getFilterQueryString, getInitialDateRange, getInitialFilter } from '../../utils';
import EntriesFilter from '../entries-filter';

const EntryList: React.FC = () => {
  const PAGE_SIZE = 10;

  const [searchParams] = useSearchParams();

  const searchInContentParam = searchParams.get('searchInContent');

  const initialFilter: IEntriesFilter = getInitialFilter(searchParams.toString() === ''
    ? undefined
    : {
      journalIds: searchParams.get('journalIds') || '',
      dateRange: getInitialDateRange(searchParams.get('minMonth'), searchParams.get('maxMonth')),
      tags: searchParams.get('tags') || '',
      searchText: searchParams.get('searchText') || '',
      searchInContent: (searchInContentParam !== null && (/true/i).test(searchInContentParam)) ?? false, // params are string or null.
    });

  const [isEntryDetailsModalOpen, setIsEntryDetailsModalOpen] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<ApiJournalEntry>();
  const [filter, setFilter] = useState<IEntriesFilter>(initialFilter);
  const [refreshList, setRefreshList] = useState<boolean>(false);

  const { isEntryEditAllowed, isEntryDeleteAllowed } = usePermissions();

  const dataUrlFn = (page:number): string => {
    const query = getFilterQueryString(filter);
    const url = getApiUrl(ApiEndpoint.FilterEntries, undefined, `?pageSize=${PAGE_SIZE}&pageNumber=${page}${query ? `&${query}` : ''}`);
    return url;
  };

  const parsePagedResultFn = (result:unknown) => {
    const res = result as ApiEntriesFilterResponse;
    return {
      parsedData: res.entries,
      parsedPage: res.page,
      parsedTotalCount: res.totalCount,
    };
  };

  const { data: deletedEntry, apiDelete } = useApiFetch();

  useEffect(() => {
    if (deletedEntry !== undefined) setRefreshList(!refreshList);
  }, [deletedEntry]);

  useEffect(() => {
    if (!filter || filter === initialFilter) return;
    setRefreshList(!refreshList);
  }, [filter]);

  const openEntry = (entry: ApiJournalEntry, e:React.MouseEvent<HTMLElement, MouseEvent>) => {
    const el = e.target as HTMLElement;
    if (el.nodeName === 'svg' || el.classList.contains('open-menu-button')) return;
    setSelectedEntry(entry);
    setIsEntryDetailsModalOpen(true);
  };

  const handleFilterChange = (newFilter: IEntriesFilter) => {
    if (newFilter === filter) return;
    setFilter(newFilter);
  };

  const showMenu = (entry: ApiJournalEntry) => isEntryEditAllowed(entry) || isEntryDeleteAllowed(entry);

  const renderListItem = (entry:ApiJournalEntry, menuAction: React.ReactElement) => (
    <React.Fragment key={entry.id}>
      <ListItem
        onClick={(e) => openEntry(entry, e)}
        sx={{
          cursor: 'pointer',
          boxShadow: (theme) => theme.shadows[5],
          border: `solid 3px ${entry.journal?.colour}`,
          my: 2,
          ':hover': {
            background: (theme) => theme.palette.grey[100],
          },
        }}
        secondaryAction={showMenu(entry) && menuAction}
      >
        <ListItemAvatar>
          <JournalAvatar entry={entry} tooltip={entry.journal?.title} />
        </ListItemAvatar>
        <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%', pr: 2 }}>
          <ListItemText primary={entry.title} secondary={moment(entry.date).format('Do MMMM, YYYY')} />
          <Hidden smDown>
            <Stack>
              {entry.tags && entry.tags.length > 0 && (
                <Stack direction="row" spacing={1}>
                  {entry.tags.map((tag) => (
                    <Chip key={tag.id} color="secondary" icon={<TagIcon />} label={tag.tag} />
                  ))}
                </Stack>
              )}
            </Stack>
            {entry.attachments && entry.attachments.length > 0 && (
            <Chip
              sx={{ justifySelf: 'end' }}
              color="secondary"
              icon={<AttachmentIcon />}
              label={entry.attachments.length}
            />
            )}
          </Hidden>
          {entry.content && entry.content !== '{}' && (
            <Tooltip title="Contains additional content. Click for more.">
              <DescriptionIcon color="secondary" />
            </Tooltip>
          )}
        </Stack>
      </ListItem>
    </React.Fragment>
  );

  return (
    <Container maxWidth="md">
      <EntriesFilter onChange={handleFilterChange} initialFilter={initialFilter} />

      <>
        <EntryDetailsModal
          entry={selectedEntry}
          open={isEntryDetailsModalOpen}
          onClose={() => setIsEntryDetailsModalOpen(false)}
        />
        <DataList
          entitySingular="Entry"
          entityPlural="Entries"
          newEntityRoute={JOURNALS_ROUTE_COLLECTION.NewEntry}
          editEntityRoute={JOURNALS_ROUTE_COLLECTION.EditEntry}
          apiDelete={apiDelete}
          apiDeleteEndpoint="/entry"
          renderListItem={renderListItem}
          paging
          pageSize={PAGE_SIZE}
          dataUrlFn={dataUrlFn}
          parsePagedResultFn={parsePagedResultFn}
          refreshList={refreshList}
          menuEditEnabledFn={isEntryEditAllowed}
          menuDeleteEnabledFn={isEntryDeleteAllowed}
        />
      </>
    </Container>
  );
};

export default EntryList;
