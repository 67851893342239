import {
  AppBar,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  Box,
  ListItem,
} from '@mui/material';
import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  APP_DETAILS,
  JOURNALS_ROUTE_COLLECTION,
  STYLE_SETTINGS,
} from 'journals-constants';
import KeyboardKeys from 'enums/keyboard-keys';
import { VERSIONING } from 'modules/version-history';
import MenuAuth from './MenuAuth';

interface IState {
    mainMenuOpen: boolean;
}

const MainMenu: React.FC = () => {
  const [state, setState] = React.useState<IState>({
    mainMenuOpen: false,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const menuGroups = [
    [
      JOURNALS_ROUTE_COLLECTION.EntryList,
      JOURNALS_ROUTE_COLLECTION.JournalList,
    ],
    [
      JOURNALS_ROUTE_COLLECTION.NewEntry,
      JOURNALS_ROUTE_COLLECTION.NewJournal,
    ],
  ];

  const toggleMainMenu = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown'
            && (event.key === KeyboardKeys.Tab || event.key === KeyboardKeys.Shift)
    ) {
      return;
    }

    setState({ ...state, mainMenuOpen: open });
  };

  const renderDrawerContent = (): JSX.Element => (
    <Box
      sx={{
        width: STYLE_SETTINGS.DRAWER_WIDTH,
      }}
      role="presentation"
      onClick={toggleMainMenu(false)}
      onKeyDown={toggleMainMenu(false)}
    >
      <AppBar position="static">
        <Toolbar>
          <Stack
            direction="row"
            justifyContent="left"
            alignItems="center"
            width="100%"
          >
            {APP_DETAILS.ICON}
            <Typography
              variant="h4"
              component="div"
              textAlign="left"
              sx={{ pl: 1 }}
            >
              {APP_DETAILS.TITLE}
            </Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <List sx={{ pt: 2 }}>
        {menuGroups.map((group, i) => (
          // Disabled because array is hardcoded. They will always have the same index.
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={i}>
            {group.map((menuItem) => (
              <ListItemButton
                key={menuItem.path}
                selected={
                                        location.pathname === menuItem.path
                                    }
                onClick={() => navigate(menuItem.path)}
              >
                <ListItemIcon>{menuItem.icon}</ListItemIcon>
                <ListItemText primary={menuItem.title} />
              </ListItemButton>
            ))}
            <Divider sx={{ my: 2 }} />
          </React.Fragment>
        ))}

        <MenuAuth />
        <Divider />
        <Divider sx={{ mt: '2px' }} />
        <ListItem
          sx={{ mt: 1, justifyContent: 'center' }}
          title="See version history"
          onClick={() => navigate(JOURNALS_ROUTE_COLLECTION.VersionHistory.path)}
        >
          <Typography
            variant="caption"
            color={(theme) => theme.palette.grey[500]}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <div>
                {VERSIONING.currentDisplayVersion}
              </div>
              <div>
                {JOURNALS_ROUTE_COLLECTION.VersionHistory.icon}
              </div>
            </Stack>
          </Typography>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <MenuIcon
        onClick={toggleMainMenu(true)}
        sx={{ display: { sm: 'none' } }}
      />
      <Drawer
        anchor="left"
        ModalProps={{
          keepMounted: true,
        }}
        open={state.mainMenuOpen}
        onClose={toggleMainMenu(false)}
        sx={{
          display: { sm: 'none' },
        }}
      >
        {renderDrawerContent()}
      </Drawer>
      <Drawer
        anchor="left"
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
      >
        {renderDrawerContent()}
      </Drawer>
    </>
  );
};

export default MainMenu;
