import { useState } from 'react';
import { IForm, IFormFieldDefinitions, IFormFieldValidationCustomRule } from '../../types/useForm';

const _ = require('lodash');

const useForm = (fieldDefinitions: IFormFieldDefinitions) => {
  const validateField = (inputForm: IForm, fieldName:string) => {
    const form = inputForm;
    const fieldDef = form.fieldDefinitions[fieldName];
    const field = form.fields[fieldName];
    field.isValid = true;
    field.errorMessage = undefined;

    if (fieldDef.validationRules && fieldDef.validationRules.isRequired && !field.value) {
      // check for required first.
      field.isValid = false;
      field.errorMessage = `${fieldDef.label || fieldName} is required.`;
    } else if (fieldDef.validationRules && fieldDef.validationRules.rules) {
      fieldDef.validationRules.rules.every((rule:IFormFieldValidationCustomRule):boolean => {
        const result = rule.validateFn(field.value);
        if (!result) {
          field.isValid = false;
          field.errorMessage = rule.invalidMsg || `${fieldDef.label || fieldName} is invalid.`;
          return false;
        }
        return true;
      });
    }

    form.isValid = Object.keys(form.fields)
      .map((fldName) => form.fields[fldName].isValid)
      .filter((isFieldValid) => !isFieldValid).length === 0;
  };

  const generateInitialFormState = (fieldDefs: IFormFieldDefinitions):IForm => {
    const initialFormState: IForm = {
      fieldDefinitions: { ...fieldDefs },
      fields: {},
      isValid: false,
      refreshFormData: () => {},
    };

    Object.keys(fieldDefs).forEach((fieldName) => {
      const fieldDef = fieldDefs[fieldName];
      initialFormState.fields[fieldName] = {
        isTouched: false,
        isValid: true,
        value: fieldDef.defaultValue,
        setValue: () => ({}),
      };
    });

    Object.keys(initialFormState.fields).forEach((fieldName) => {
      validateField(initialFormState, fieldName);
    });

    return initialFormState;
  };

  const initialState = generateInitialFormState(fieldDefinitions);
  const [form, setForm] = useState<IForm>(initialState);

  const formToReturn:IForm = _.cloneDeep(form);
  Object.keys(formToReturn.fields).forEach((fieldName) => {
    formToReturn.fields[fieldName].setValue = (value) => {
      const newFormWithValue = _.cloneDeep(form);
      const field = newFormWithValue.fields[fieldName];
      field.isTouched = true;
      field.value = value;
      validateField(newFormWithValue, fieldName);
      setForm(newFormWithValue);
    };
  });

  formToReturn.refreshFormData = (newFieldDefinitions: IFormFieldDefinitions) => setForm(generateInitialFormState(newFieldDefinitions));

  return formToReturn;
};

export default useForm;
