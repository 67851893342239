import {
  Avatar, Badge, SxProps, Tooltip,
} from '@mui/material';
import React from 'react';
import { ApiJournal, ApiJournalEntry } from '../../types/api';
import { UserAvatar } from '..';

interface IProps {
    journal?: ApiJournal;
    entry?: ApiJournalEntry;
    tooltip?: string;
    hideBadge?: boolean;
    sx?: SxProps;
}

const JournalAvatar: React.FC<IProps> = ({
  journal: inputJournal,
  entry,
  tooltip,
  hideBadge,
  sx,
}) => {
  if (!inputJournal && !entry) throw new Error('Journal or entry must be passed.');
  const isShowingEntry = entry !== undefined;
  const journal = isShowingEntry ? entry.journal : inputJournal;

  if (journal === undefined) return <> </>;

  const owner = isShowingEntry ? entry.owner : journal.owner;

  const avatar = <Avatar sx={{ ...sx, border: `solid 3px ${journal.colour}` }} alt={journal.title} src={journal.avatar} />;

  if (hideBadge) {
    return (
      <>
        {tooltip && <Tooltip title={tooltip}>{avatar}</Tooltip>}
        {!tooltip && avatar}
      </>
    );
  }

  return (
    <Badge
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      overlap="circular"
      title={`${isShowingEntry ? 'Created' : 'Owned'} by ${owner!.name}`}
      badgeContent={<UserAvatar user={owner!} small />}
    >
      {tooltip && <Tooltip title={tooltip}>{avatar}</Tooltip>}
      {!tooltip && avatar}
    </Badge>
  );
};

export default JournalAvatar;
