/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import useApiFetch from 'hooks/use-api-fetch';
import { ApiEndpoint } from 'enums/api';

interface IProps{
    value: Array<string>,
    onChange: (newValue: Array<string>) => void
}

const TagsInput: React.FC<IProps> = ({ value, onChange }) => {
  const { data: existingTags, isLoading: isExistingTagsLoading } = useApiFetch(ApiEndpoint.GetTags, true);

  return (
    <Autocomplete
      fullWidth
      multiple
      freeSolo
      loading={isExistingTagsLoading}
      options={existingTags as string[] || []}
      value={value}
      onChange={(e, val) => onChange(val as Array<string>)}
      renderInput={(params) => <TextField {...params} label="Tags" placeholder="Enter tags" />}
    />
  );
};

export default React.memo(TagsInput);
