import {
  Button, Chip, Divider, Stack, Typography, Box,
} from '@mui/material';
import React, { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import TagIcon from '@mui/icons-material/Bookmark';
import { OutputData } from '@editorjs/editorjs';
import { useNavigate } from 'react-router-dom';
import { formatFromUtc } from 'utils/date-utils';
import { generateRouteWithId } from 'utils/route-utils';
import useApiFetch from 'hooks/use-api-fetch';
import { JOURNALS_ROUTE_COLLECTION } from 'journals-constants';
import moment from 'moment';
import { getApiUrl } from 'utils/api-utils';
import { ApiEndpoint } from 'enums/api';
import AppModal from '../app-modal/AppModal';
import { ApiJournalEntry, ApiOperation } from '../../types/api';
import UserAvatar from '../user-avatar/UserAvatar';
import { AttachmentList, ParsedEditorData } from '..';

interface IProps{
    entry?: ApiJournalEntry,
    open: boolean,
    onClose: () => void
}

const EntryDetailsModal: React.FC<IProps> = ({ entry, open, onClose }) => {
  const navigate = useNavigate();

  const { data: lastModifiedOperation, apiGet } = useApiFetch();
  const parsedOperation = lastModifiedOperation as ApiOperation;

  useEffect(() => {
    if (!entry) return;
    apiGet(getApiUrl(ApiEndpoint.EntryOperations, entry.id, 'lastModified=true'));
  }, [entry]);

  if (!entry) return <> </>;

  return (
    <AppModal
      open={open}
      onClose={onClose}
      title={entry.title}
      contentSx={{ py: 2, overflow: 'auto' }}
      subtitle={(
        <>
          <Stack direction="row" alignItems="center" sx={{ pt: 0.5 }}>
            <UserAvatar user={entry.owner!} small />
            <Typography variant="caption" sx={{ pl: 1 }}>
              {formatFromUtc(moment(entry.date))}
            </Typography>
          </Stack>
          {lastModifiedOperation && parsedOperation.operationType !== 'CreateEntry' && (
          <Stack direction="row" alignItems="center" sx={{ pt: 0.5 }}>
            <UserAvatar user={parsedOperation.user} small />
            <Typography variant="caption" sx={{ pl: 1 }}>
              last modified
              {' '}
              {formatFromUtc(moment(parsedOperation.date), true)}
            </Typography>
          </Stack>
          )}
        </>
              )}
      renderActionButtons={() => (
        <Button
          variant="contained"
          endIcon={<EditIcon />}
          onClick={() => navigate(generateRouteWithId(JOURNALS_ROUTE_COLLECTION.EditEntry, entry.id!))}
        >
          Edit
        </Button>
      )}
    >
      <Box
        sx={{
          mx: 0,
          px: 0,
        }}
      >
        {entry.tags && entry.tags.length > 0 && (
        <>
          <Stack direction="row" sx={{ px: 2 }} flexWrap="wrap" gap={1}>
            {entry.tags.map((tag) => (
              <Chip key={tag.id} icon={<TagIcon />} label={tag.tag} />
            ))}
          </Stack>
          <Divider sx={{ my: 2 }} />
        </>
        )}
        <Box sx={{ px: 2 }}>
          {(!entry.content || entry.content === '{}') && (
            <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
              This entry has no additional content.
            </Typography>
          )}
          <ParsedEditorData editorData={JSON.parse(entry.content as string) as OutputData} />
        </Box>
        <Box sx={{ px: 0 }}>
          {entry.attachments && entry.attachments.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <AttachmentList attachmentList={entry.attachments} hideShadow hideTitle />
            </>
          )}
        </Box>
      </Box>
    </AppModal>
  );
};

export default EntryDetailsModal;
