import { ListItemAvatar, ListItemText } from '@mui/material';
import React from 'react';
import { JournalAvatar } from '..';
import { ApiJournal } from '../../types/api';

const JournalSelectOptionJournal: React.FC<{journal: ApiJournal}> = ({ journal }) => (
  <>
    <ListItemAvatar>
      <JournalAvatar journal={journal} />
    </ListItemAvatar>
    <ListItemText primary={journal.title} />
  </>
);

export default React.memo(JournalSelectOptionJournal);
