import { ApiEndpoint } from 'enums/api';
import { SavingStatus } from 'types/api';

const getApiUrl = (endpoint: ApiEndpoint, routeParams?: string|string[], queryString?: string): string => {
  let url = endpoint.toString();

  let params = [];
  if (typeof (routeParams) === 'string') {
    params.push(routeParams);
  } else params = routeParams as string[];

  if (params && params.length !== 0) {
    params.forEach((param) => {
      url += `/${param}`;
    });
  }
  if (queryString) {
    if (!queryString.startsWith('?')) {
      url += '?';
    }
    url += queryString;
  }

  return url;
};

const getSavingStatus = (isLoading: boolean, data?: unknown, error?: string):SavingStatus => {
  if (isLoading) return SavingStatus.Saving;
  if (error) return SavingStatus.Error;
  if (data) return SavingStatus.Saved;
  return SavingStatus.NotSaved;
};

// eslint-disable-next-line import/prefer-default-export
export { getApiUrl, getSavingStatus };
