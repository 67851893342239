/* eslint-disable no-shadow */
enum KeyboardKeys{
    Tab = 'Tab',
    Shift = 'Shift',
    Enter = 'Enter',
    F = 'f',
    ArrowRight = 'ArrowRight',
    ArrowLeft = 'ArrowLeft',
}

export default KeyboardKeys;
