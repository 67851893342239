import { useLocation } from 'react-router-dom';
import { getParsedMatchedRouteDetails } from 'utils/route-utils';
import { JournalsRoute } from '../../types/routing';

/**
 * Hook to return the parsed route based on the current location path.
 * @returns Parsed route.
 */
const useRouteDetails = (): JournalsRoute => {
  const location = useLocation();
  return getParsedMatchedRouteDetails(location.pathname);
};

export default useRouteDetails;
