import React from 'react';
import ReactDOMServer from 'react-dom/server';
import BlockIcon from '@mui/icons-material/Ballot';
import { API, BlockTune } from '@editorjs/editorjs';

export interface IBulletWithHeading{
  heading: string,
  value: string|number
}

interface IBulletsWithHeadingData{
  bullets: IBulletWithHeading[];
}

// look at https://github.com/editor-js/footnotes-tune/blob/main/src/index.ts
class BulletsWithHeading implements BlockTune {
  static get toolbox() {
    return {
      title: 'Bullets with heading',
      icon: ReactDOMServer.renderToStaticMarkup(<BlockIcon sx={{ width: 17, height: 17 }} />),
    };
  }

  private api: API;

  private data : IBulletsWithHeadingData;

  private blockContent?: HTMLElement;

  private keyUpListener?: () => void;

  constructor({
    data, api,
  }
  :{ data: IBulletsWithHeadingData, api: API}) {
    this.api = api;

    this.data = {
      bullets: data.bullets ?? [],
    };

    this.keyUpListener = () => this.onBulletsChange();
  }

  renderRow(key: number, bullet?:IBulletWithHeading): React.ReactElement {
    return (
      <div
        key={key}
        className="bullet"
        style={{
          display: 'flex',
          padding: '4px',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <input
            type="text"
            className="heading cdx-input"
            defaultValue={bullet?.heading ?? ''}
            placeholder="Heading"
            style={{ fontWeight: 'bold' }}
            onKeyUp={() => this.onBulletsChange()}
          />
        </div>
        <div>
          <input
            type="text"
            className="value cdx-input"
            defaultValue={bullet?.value ?? ''}
            placeholder="Value"
            onKeyUp={() => this.onBulletsChange()}
          />
        </div>
      </div>
    );
  }

  getBulletsFromInputs(excludeEmpty?: boolean): IBulletWithHeading[] {
    const bulletDivs:HTMLElement[] = Array.from(this.blockContent!.querySelectorAll('.bullet')!);
    const bullets = bulletDivs
      .map((bulletDiv) => ({
        heading: (bulletDiv.querySelector('.heading') as HTMLInputElement)?.value ?? '',
        value: (bulletDiv.querySelector('.value') as HTMLInputElement)?.value ?? '',
      }));

    return excludeEmpty
      ? bullets.filter((bullet) => Boolean(bullet.heading) || Boolean(bullet.value))
      : bullets;
  }

  updateKeyUpListeners() {
    const inputs = Array.from(this.blockContent!.querySelectorAll('input')!);
    if (inputs.length < 2) return;

    inputs.forEach((input) => {
      input.removeEventListener('keyup', this.keyUpListener!);
    });

    inputs.slice(-2).forEach((input) => {
      input.addEventListener('keyup', this.keyUpListener!);
    });
  }

  onBulletsChange() {
    const lastBullet = this.getBulletsFromInputs().at(-1)!;
    if (!lastBullet.heading && !lastBullet.value) return;

    this.blockContent?.insertAdjacentHTML(
      'beforeend',
      ReactDOMServer.renderToStaticMarkup(this.renderRow(this.data.bullets.length + 1)),
    );

    this.updateKeyUpListeners();
    this.save();
  }

  render(): HTMLElement {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = ReactDOMServer.renderToStaticMarkup(
      <div
        className="cdx-block"
        style={{ boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}
      >
        {this.data.bullets.map((bullet, i) => this.renderRow(i, bullet))}
        {this.renderRow(this.data.bullets.length + 1)}
      </div>,
    );
    this.blockContent = wrapper.firstChild as HTMLElement;
    this.updateKeyUpListeners();
    return this.blockContent;
  }

  save(): IBulletsWithHeadingData {
    return {
      bullets: this.getBulletsFromInputs(true),
    };
  }
}

export default BulletsWithHeading;
