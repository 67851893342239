import {
  Card, CardContent, Container, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography,
} from '@mui/material';
import React from 'react';
import AddedIcon from '@mui/icons-material/AddCircle';
import UpdatedIcon from '@mui/icons-material/ChangeCircle';
import DeletedIcon from '@mui/icons-material/RemoveCircle';
import FixedIcon from '@mui/icons-material/PestControl';
import { ChangeType, VersionChange } from './types';
import VERSIONING from './versioning';

const renderIcon = (change: VersionChange): React.ReactElement => {
  switch (change.type) {
    case ChangeType.Added: return <AddedIcon color="success" />;
    case ChangeType.Updated: return <UpdatedIcon color="primary" />;
    case ChangeType.Deleted: return <DeletedIcon color="error" />;
    case ChangeType.Fixed: return <FixedIcon color="warning" />;
    default: return <AddedIcon color="success" />;
  }
};

const VersionHistory = () => (
  <Container maxWidth="md" sx={{ p: 0 }}>
    <Stack>
      {VERSIONING.versionHistory.map((vc) => (
        <Card key={vc.version} sx={{ mb: 3 }}>
          <CardContent>
            <Stack sx={{ alignItems: 'center' }}>
              <Typography variant="h2">{vc.version}</Typography>
              <Typography variant="caption" sx={{ mt: 1 }}>{vc.releaseDate}</Typography>
            </Stack>
            <Divider component="div" variant="middle" sx={{ my: 2 }} />
            <List>
              {vc.changes.map((change) => (
                <ListItem key={change.text}>
                  <ListItemIcon>
                    {renderIcon(change)}
                  </ListItemIcon>
                  <ListItemText>
                    {change.text}
                  </ListItemText>
                </ListItem>
              ))}
            </List>

          </CardContent>
        </Card>
      ))}

    </Stack>
  </Container>

);

export default VersionHistory;
