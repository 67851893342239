import {
  AppBar, IconButton, Toolbar, Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { UserAvatar } from 'components';
import useRouteDetails from 'hooks/use-route-details';
import { JournalsContext } from 'context/JournalsContext';
import MainMenu from '../main-menu/MainMenu';

const Header: React.FC = () => {
  const { title: activeRouteTitle } = useRouteDetails();
  const { user } = useContext(JournalsContext);
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MainMenu />
        </IconButton>
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
          {activeRouteTitle}
        </Typography>
        {user && <UserAvatar user={user} />}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
