import { Crop } from 'react-image-crop';

const getCroppedImgBlob = (image: HTMLImageElement, crop: Crop): Promise<Blob> => {
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const scaleX: number = image.naturalWidth / image.width;
  const scaleY: number = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx: CanvasRenderingContext2D|null = canvas.getContext('2d');

  if (!ctx) throw new Error('canvas context not found.');

  const pixelRatio:number = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  // As Base64 string
  // const base64Image = canvas.toDataURL("image/jpeg");
  // return base64Image;

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) throw new Error('Could not get blob to resolve');
        resolve(blob);
      },
      'image/png',
      1,
    );
  });
};

const translateAspect = (aspectType: 'square'|'rectangle'|'freeform'): number => {
  switch (aspectType) {
    case 'square': return 1 / 1;
    case 'rectangle': return 16 / 9;
    default: return 0;
  }
};

export { getCroppedImgBlob, translateAspect };
