import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField, Theme, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FilterIcon from '@mui/icons-material/FilterAlt';
import {
  LoadingBackdrop, AppModal, TagsInput, JournalSelect,
} from 'components';
import useApiFetch from 'hooks/use-api-fetch';
import KeyboardKeys from 'enums/keyboard-keys';
import { ApiJournal } from 'types/api';
import { ApiEndpoint } from 'enums/api';
import { getInitialFilter, getUpdatedFilterChips } from '../../utils';
import { DEFAULT_FILTER } from '../../constants';
import { IEntriesFilter, IFilterChip } from '../../types';
import EntriesFilterDateRange from './EntriesFilterDateRange';
import EntriesFilterChips from './EntriesFilterChips';

interface IProps{
    initialFilter: IEntriesFilter,
    onChange: (newFilter: IEntriesFilter) => void;
}

const EntriesFilter : React.FC<IProps> = ({ initialFilter: inputFilter, onChange }) => {
  const initialFilter = getInitialFilter(inputFilter);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [tags, setTags] = useState<Array<string>>(inputFilter.tags ? inputFilter.tags.split(',') : []);
  const [filterValues, setFilterValues] = useState<IEntriesFilter>(initialFilter);
  const [journalsLoadedFirstTime, setJournalsLoadedFirstTime] = useState<boolean>(false);
  const { data: journals, isLoading, error } = useApiFetch(ApiEndpoint.Journal, true);
  const [filterChips, setFilterChips] = useState<Array<IFilterChip>>([]);

  const theme: Theme = useTheme();
  const isLargeVersion: boolean = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  const updateFilterChips = (updatedValues: IEntriesFilter): void => {
    const newChips = getUpdatedFilterChips(updatedValues, journals as ApiJournal[], {
      setTags,
    });
    setFilterChips(newChips);
  };

  const onChipDelete = (chip:IFilterChip) => {
    let newFilterValues:IEntriesFilter = { ...filterValues };
    if (chip.setFn) chip.setFn(newFilterValues);
    newFilterValues = chip.getUpdatedFilterOnDelete(newFilterValues);
    setFilterValues(newFilterValues);
    updateFilterChips(newFilterValues);
    if (onChange) onChange(newFilterValues);
  };

  const saveFilter = () => {
    if (onChange) onChange(filterValues);
    setIsFilterOpen(false);
    updateFilterChips(filterValues);
  };

  const clearFilter = () => {
    setFilterValues(DEFAULT_FILTER);
    setTags([]);
  };

  const onTagsChange = (value:Array<string>) => {
    setTags(value);
    setFilterValues({ ...filterValues, tags: value.join(',') });
  };

  const onJournalChange = (value:string|Array<string>) => setFilterValues(
    { ...filterValues, journalIds: (value as Array<string>).join(',') },
  );

  const onInputKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KeyboardKeys.Enter) saveFilter();
  };

  const keyupListener = (keyupEvent: KeyboardEvent) => {
    const { key, target, repeat } = keyupEvent;

    if (repeat) return;
    if (key !== KeyboardKeys.F) return;
    if (!(target instanceof HTMLElement) || target.tagName !== 'BODY') return;
    if (isFilterOpen) return;

    setIsFilterOpen(true);
  };

  useEffect(() => {
    window.addEventListener('keyup', keyupListener);

    return () => {
      window.removeEventListener('keyup', keyupListener);
    };
  }, []);

  useEffect(() => {
    if (!journals) return;
    if (!journalsLoadedFirstTime) {
      updateFilterChips(filterValues);
      setJournalsLoadedFirstTime(true);
    }
  }, [journals]);

  if (isLoading) return <LoadingBackdrop />;

  if (error !== undefined) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        There was an error retrieving the list of journals.
      </Alert>
    );
  }

  if (journals && (journals as ApiJournal[]).length === 0) {
    return (
      <Alert severity="warning">
        <AlertTitle>Warning</AlertTitle>
        You have no data yet. Start adding journals first.
      </Alert>
    );
  }

  if (!journals) return <> </>;

  return (
    <>
      <Stack direction={isLargeVersion ? 'row' : 'column'} justifyContent="space-between">
        <EntriesFilterChips filterChips={filterChips} onChipDelete={onChipDelete} />
        <Box>
          <Button
            sx={{ mt: isLargeVersion ? 0 : 2 }}
            variant="outlined"
            color="secondary"
            endIcon={<FilterIcon />}
            onClick={() => setIsFilterOpen(true)}
          >
            <strong>[F]</strong>
            &nbsp;
            Filter
          </Button>
        </Box>
      </Stack>

      <AppModal
        open={isFilterOpen}
        title="Filter entries"
        onClose={() => setIsFilterOpen(false)}
        width="400px"
        contentSx={{ py: 2, overflow: 'auto' }}
        renderActionButtons={() => (
          <>
            <Button variant="outlined" color="error" onClick={clearFilter}>
              Clear
            </Button>
            <Button variant="contained" onClick={saveFilter}>
              Save
            </Button>
          </>
        )}
      >
        <Box sx={{ px: 2 }}>
          <Stack gap={2}>
            <Divider>Search</Divider>
            <TextField
              label="Search"
              placeholder="Search text in entries."
              value={filterValues.searchText}
              autoFocus
              onChange={(e) => setFilterValues({ ...filterValues, searchText: e.target.value })}
              onKeyUp={onInputKeyUp}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={filterValues.searchInContent}
                  onChange={(e) => setFilterValues({ ...filterValues, searchInContent: e.target.checked })}
                />
                              )}
              label="Search in content"
            />
            <Divider>Journal / Tags</Divider>
            <JournalSelect
              journals={journals as ApiJournal[]}
              multiple
              selectedJournalId={filterValues.journalIds ? filterValues.journalIds.split(',') : []}
              onChange={onJournalChange}
            />
            <TagsInput value={tags} onChange={onTagsChange} />
            <EntriesFilterDateRange
              dateRange={filterValues.dateRange}
              onChange={(v) => setFilterValues({ ...filterValues, dateRange: v })}
            />
          </Stack>
        </Box>
      </AppModal>
    </>
  );
};

export default React.memo(EntriesFilter);
