/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import ReactDOMServer from 'react-dom/server';
import CalendarIcon from '@mui/icons-material/DateRange';
import moment from 'moment';
import BulletIcon from '@mui/icons-material/ArrowRightRounded';
import DOMPurify from 'dompurify';
import {
  Alert, AlertTitle, Box, Divider, Stack, Table, TableBody, TableCell, TableRow, ThemeProvider, Typography,
} from '@mui/material';
import React from 'react';
import Header from '@editorjs/header';
import Embed from '@editorjs/embed';
import EditorJsList from '@editorjs/list';
import Warning from '@editorjs/warning';
import Marker from '@editorjs/marker';
import ImageTool from '@editorjs/image';
import { OutputData } from '@editorjs/editorjs';
import { JOURNALS_THEME } from 'journals-constants';
import { ApiEndpoint } from 'enums/api';
import { IBulletWithHeading } from 'plugins/editor-plugins/bullets-with-heading/bullets-with-heading';
import BulletHeadingIcon from '@mui/icons-material/Circle';
import { BulletsWithHeading, DateEntries } from '../plugins/editor-plugins';

const getEditorTools = (apiPost: (
  endpoint: string,
  body: unknown,
  returnResponseObject?: boolean
) => Promise<Response | undefined>) => ({
  header: {
    class: Header,
    inlineToolbar: true,
  },
  embed: Embed,
  list: {
    class: EditorJsList,
    inlineToolbar: true,
  },
  warning: {
    class: Warning,
    inlineToolbar: false,
  },
  dateEntries: DateEntries,
  bulletsWithHeading: BulletsWithHeading,
  image: {
    class: ImageTool,
    inlineToolbar: true,
    config: {
      uploader: {
        uploadByFile(file: File) {
          // your own uploading logic here
          const formData = new FormData();
          formData.append('formFile', file);
          formData.append('fileType', file.type);
          formData.append('fileName', file.name);

          return apiPost(ApiEndpoint.UploadEditorImage, formData, true).then((res) => res?.json() ?? {});
        },
      },
    },
  },
  marker: Marker,
});

const renderWithTheme = (jsxBlocks: Array<React.ReactElement>) => ReactDOMServer.renderToStaticMarkup(
  <ThemeProvider theme={JOURNALS_THEME}>
    {jsxBlocks.map((block, i) => (
      // Dynamic, nothing to identify key. They will not change.
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={i}>{block}</React.Fragment>
    ))}
  </ThemeProvider>,
);

const getTypographyVariant = (level:number) => {
  switch (level) {
    case 1: return 'h1';
    case 2: return 'h2';
    case 3: return 'h3';
    case 4: return 'h4';
    case 5: return 'h5';
    case 6: return 'h6';
    default: return 'body1';
  }
};

const parseEditorJsData = (editorData: OutputData) => {
  if (!('blocks' in editorData)) return '';

  const parsedBlocks: Array<React.ReactElement> = editorData.blocks
    .map((block) => {
      switch (block.type) {
        case 'header':
          return (
            <Typography variant={getTypographyVariant(block.data.level)} pt={2} pb={1}>
              <span dangerouslySetInnerHTML={{ __html: block.data.text }} />
            </Typography>
          );
        case 'paragraph':
          return (
            <Typography variant="body1" py={1} textAlign="justify">
              <span dangerouslySetInnerHTML={{
                __html: (block.data.text as string)
                  .replaceAll('<a href', '<a target="_blank" rel="noreferrer" href'),
              }}
              />
            </Typography>
          );
        case 'image':
          return (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={block.data.file.url} alt={block.data.caption} style={{ maxWidth: '100%' }} />
            </Box>
          );
        case 'simpleImage':
          return (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img src={block.data.url} alt={block.data.caption} style={{ maxWidth: '100%' }} />
            </Box>
          );
        case 'list':
          return (
            <Stack gap={1}>
              {block.data.items.map((item: string, index: number) => (
                <Stack key={item} direction="row" gap={1} alignItems="center">
                  {block.data.style === 'unordered' ? (
                    <BulletIcon />
                  ) : (
                    <Typography variant="subtitle2" pl={1} pr={0.5}>
                      <strong>
                        {index + 1}
                        .
                      </strong>
                    </Typography>
                  )}
                  <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item }} />
                </Stack>
              ))}
            </Stack>
          );
        case 'warning':
          return (
            <Alert severity="warning" sx={{ my: 1 }}>
              <AlertTitle>{block.data.title}</AlertTitle>
              {' '}
              <span dangerouslySetInnerHTML={{ __html: block.data.message }} />
            </Alert>
          );
        case 'dateEntries':
          return (
            <>
              <Stack direction="row" alignItems="center" gap={1} sx={{ mt: 1 }}>
                <Box>
                  <CalendarIcon />
                </Box>
                <Typography variant="caption" sx={{ whiteSpace: 'nowrap' }}>
                  {block.data.date ? moment(block.data.date).format('DD MMM YYYY') : 'Invalid Date'}
                </Typography>
                <Divider orientation="vertical" flexItem />
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: block.data.message }}
                />
              </Stack>
              <Divider sx={{ my: 1 }} />
            </>
          );
        case 'bulletsWithHeading': {
          if (block.data.bullets.length === 0) return <> </>;

          return (
            <Table size="small">
              <TableBody>
                {block.data.bullets.map((bullet:IBulletWithHeading, i:number) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row" sx={{ width: '1%', whiteSpace: 'nowrap', px: 0 }}>
                      <strong>
                        {bullet.heading}
                      </strong>
                    </TableCell>
                    <TableCell sx={{ width: '5px', px: 1 }}>
                      <Stack direction="row">
                        <BulletHeadingIcon sx={{ fontSize: 5, color: 'silver' }} />
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ px: 0 }}>
                      {bullet.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          );
        }
        default:
          return (
            <Alert severity="error" sx={{ my: 1 }}>
              <AlertTitle>Cannot render content.</AlertTitle>
              Please contact administrator.
            </Alert>
          );
      }
    });

  const parsedHtml = renderWithTheme(parsedBlocks);
  const cleanHTML = DOMPurify.sanitize(parsedHtml, {
    USE_PROFILES: { html: true, svg: true },
    ADD_ATTR: ['target'],
  });
  return cleanHTML;
};

export { getEditorTools, parseEditorJsData };
