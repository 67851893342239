import React from 'react';
import ReactDOMServer from 'react-dom/server';
import DateIcon from '@mui/icons-material/DateRange';
import moment from 'moment';
import { API, BlockTune } from '@editorjs/editorjs';

interface IDateEntriesData{
    date: string,
    message: string
}

// look at https://github.com/editor-js/footnotes-tune/blob/main/src/index.ts
class DateEntries implements BlockTune {
  static get toolbox() {
    return {
      title: 'Date Entry',
      icon: ReactDOMServer.renderToStaticMarkup(<DateIcon sx={{ width: 17, height: 17 }} />),
    };
  }

  static getCurrentDate = (): string => moment().format('DD/MM/YYYY');

  private api: API;

  private data : IDateEntriesData;

  private blockContent?: HTMLElement;

  constructor({
    data, api,
  }
  :{ data: IDateEntriesData, api: API}) {
    this.api = api;

    this.data = {
      date: data.date || DateEntries.getCurrentDate(),
      message: data.message || '',
    };
  }

  render(): HTMLElement {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = ReactDOMServer.renderToStaticMarkup(
      <div className="cdx-block">
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '4px',
          boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }}>
            <DateIcon sx={{ alignSelf: 'center', mr: 2 }} />
            <input
              type="date"
              style={{ marginRight: '2px', minWidth: '150px', width: 'auto' }}
              className="date-input cdx-input"
              defaultValue={this.data.date}
            />
          </div>
          <div style={{ paddingTop: '5px' }}>
            <textarea
              placeholder="Enter message"
              style={{ resize: 'vertical' }}
              className="message-input cdx-input"
              defaultValue={this.data.message}
            />
          </div>
        </div>
      </div>,
    );
    this.blockContent = wrapper.firstChild as HTMLElement;
    return this.blockContent;
  }

  save(): IDateEntriesData {
    return {
      date: (this.blockContent?.querySelector('.date-input') as HTMLInputElement)?.value ?? DateEntries.getCurrentDate(),
      message: (this.blockContent?.querySelector('.message-input') as HTMLInputElement)?.value ?? '',
    };
  }
}

export default DateEntries;
