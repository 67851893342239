import React from 'react';
import {
  Box, Button, Divider, Modal, Stack, SxProps, Theme, ThemeProvider, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { JOURNALS_THEME_DARK } from 'journals-constants';

interface IProps{
    open: boolean;
    title: string|React.ReactElement;
    subtitle?: string|React.ReactElement;
    width?:string;
    modalSx?: SxProps<Theme>;
    contentSx?: SxProps<Theme>;
    fullScreen?: boolean;
    dark?: boolean;
    onClose?: () => void;
    renderActionButtons?: () => React.ReactElement;
}

const defaultModalContentStyle: SxProps<Theme> = {
  alignSelf: 'center',
};

const AppModal: React.FC<React.PropsWithChildren<IProps>> = ({
  open, onClose, title, subtitle, children, renderActionButtons, modalSx, contentSx, width, fullScreen, dark,
}): React.ReactElement => {
  const theme = useTheme();

  const isLargeVersion = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const defaultModalWidth = `min(100vw,${theme.breakpoints.values.lg}px)`;

  const getModalWidth = ():string => {
    if (fullScreen || !isLargeVersion) return '100vw';

    if (width) return width;

    return defaultModalWidth;
  };

  const modalWidth = getModalWidth();

  const defaultModalStyle: SxProps<Theme> = {
    width: modalWidth,
    height: isLargeVersion && !fullScreen ? 'calc(100vh - 64px)' : '100vh',
    position: 'absolute',
    top: isLargeVersion && !fullScreen ? 'calc(50% + 32px)' : '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: dark ? JOURNALS_THEME_DARK.palette.background.paper : theme.palette.background.paper,
  };

  const modalStyle: SxProps<Theme> = modalSx ? { ...defaultModalStyle, ...modalSx } : { ...defaultModalStyle };

  const modalContentStyle: SxProps<Theme> = {
    ...defaultModalContentStyle,
    width: modalWidth,
  };

  const handleClose = (): void => {
    if (!onClose) return;
    onClose();
  };

  const defaultContentStyle: SxProps<Theme> = {
    flex: 1,
    width: modalWidth,
  };

  const contentStyle = contentSx ? { ...defaultContentStyle, ...contentSx } : { ...defaultContentStyle };

  return (
    <ThemeProvider theme={(outerTheme) => (dark ? JOURNALS_THEME_DARK : outerTheme)}>
      <Modal open={open} onClose={handleClose}>
        <Stack sx={modalStyle} justifyContent="space-between">

          <Stack sx={modalContentStyle}>
            <Box p={2}>
              <Typography variant="h4">{title}</Typography>
              {subtitle}
            </Box>
            <Divider />
          </Stack>
          <Stack sx={contentStyle}>
            {children}
          </Stack>
          <Stack sx={{ width: modalWidth, alignSelf: 'center' }}>
            <Divider />
            <Stack direction="row" justifyContent="end" spacing={1} sx={{ p: 2 }}>

              {renderActionButtons && renderActionButtons()}
              <Button variant="outlined" endIcon={<CloseIcon />} onClick={handleClose}>
                Close
              </Button>
            </Stack>
          </Stack>

        </Stack>
      </Modal>
    </ThemeProvider>

  );
};

export default AppModal;
