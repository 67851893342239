import { ApiFetchAction, ApiFetchActionType, IApiFetchState } from './types';

const reducer = (state: IApiFetchState, action: ApiFetchAction): IApiFetchState => {
  const { type, payload } = action;

  switch (type) {
    case ApiFetchActionType.StartRequest:
      return {
        ...state,
        data: undefined,
        isLoading: true,
        error: undefined,
      };
    case ApiFetchActionType.RequestSuccess:
      return {
        ...state,
        data: payload,
        isLoading: false,
        error: undefined,
      };
    case ApiFetchActionType.RequestError:
      return {
        ...state,
        data: undefined,
        isLoading: false,
        error: payload as string,
      };
    default:
      return state;
  }
};

export default reducer;
