import './App.css';

import React from 'react';
import {
  Route, BrowserRouter, Routes,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LocalizationProvider } from '@mui/lab';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import DateAdapter from '@mui/lab/AdapterMoment';
import {
  Button, Container, ThemeProvider, Box,
} from '@mui/material';
import { LoadingBackdrop } from 'components';
import { EditEntry, EntryForm } from 'modules/edit-entry';
import { VersionHistory } from 'modules/version-history';
import EditJournalPermissions from 'modules/edit-journal-permissions';
import {
  JOURNALS_ROUTE_COLLECTION,
  STYLE_SETTINGS,
  JOURNALS_THEME,
} from './journals-constants';
import { Header, Footer } from './modules/layout';
import Login from './modules/login';
import { JournalsContextProvider } from './context/JournalsContext';
import LandingComponent from './modules/landing';
import JournalList from './modules/journal-list';
import { EditJournal, JournalForm } from './modules/edit-journal';
import EntryList from './modules/entry-list';

function App() {
  const { isLoading: isAuthLoading, isAuthenticated } = useAuth0();

  if (isAuthLoading) {
    return (
      <ThemeProvider theme={JOURNALS_THEME}>
        <LoadingBackdrop hideBackground />
      </ThemeProvider>
    );
  }

  if (!isAuthenticated) {
    return (
      <ThemeProvider theme={JOURNALS_THEME}>
        <Login />
      </ThemeProvider>
    );
  }

  const notistackRef = React.createRef<SnackbarProvider>();
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key);
  };

  const snackbarDismissAction = (key: SnackbarKey): React.ReactElement => (
    <Button onClick={onClickDismiss(key)} sx={{ color: 'white' }}>
      Dismiss
    </Button>
  );

  const body = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        ml: { sm: `${STYLE_SETTINGS.DRAWER_WIDTH}px` },
      }}
    >
      <Header />
      <Container component="main" sx={{ mt: 4, mb: 2 }} maxWidth={false}>
        <Routes>
          <Route path={JOURNALS_ROUTE_COLLECTION.Home.path}>
            <Route index element={<LandingComponent />} />
            <Route
              path={JOURNALS_ROUTE_COLLECTION.JournalList.path}
              element={<JournalList />}
            />
            <Route path={JOURNALS_ROUTE_COLLECTION.NewJournal.path} element={<JournalForm />} />
            <Route path={JOURNALS_ROUTE_COLLECTION.EditJournal.pathForRoute} element={<EditJournal />} />
            <Route path={JOURNALS_ROUTE_COLLECTION.EntryList.path} element={<EntryList />} />
            <Route path={JOURNALS_ROUTE_COLLECTION.NewEntry.path} element={<EntryForm />} />
            <Route path={JOURNALS_ROUTE_COLLECTION.EditEntry.pathForRoute} element={<EditEntry />} />
            <Route path={JOURNALS_ROUTE_COLLECTION.VersionHistory.path} element={<VersionHistory />} />
            <Route path={JOURNALS_ROUTE_COLLECTION.EditJournalPermissions.pathForRoute} element={<EditJournalPermissions />} />
          </Route>
        </Routes>
      </Container>
      <Footer />
    </Box>
  );

  return (
    <JournalsContextProvider>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <ThemeProvider theme={JOURNALS_THEME}>
            <SnackbarProvider
              ref={notistackRef}
              maxSnack={3}
              action={snackbarDismissAction}
            >
              {body}
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </JournalsContextProvider>
  );
}

export default App;
