import {
  Checkbox,
  Chip,
  FormControl,
  InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useForm from 'hooks/use-form';
import { JournalAvatar } from '..';
import JournalSelectOptionJournal from './JournalSelectOptionJournal';
import { ApiJournal } from '../../types/api';

interface IProps{
    journals: Array<ApiJournal>,
    selectedJournalId: string|Array<string>,
    onChange: (value:string|Array<string>) => void,
    multiple?: boolean,
    autoOpen?:boolean
}

const JournalSelect: React.FC<IProps> = ({
  journals, multiple, selectedJournalId, onChange, autoOpen,
}) => {
  const [open, setOpen] = useState(autoOpen ?? false);

  const form = useForm({
    journalIdField: {
      label: 'Journal',
      defaultValue: selectedJournalId,
      validationRules: {
        isRequired: false,
      },
    },
  });
  const { journalIdField } = form.fields;

  useEffect(() => {
    if (selectedJournalId !== journalIdField.value) journalIdField.setValue(selectedJournalId);
  }, [selectedJournalId]);

  const onSelectChange = (e:SelectChangeEvent<string|string[]>) => {
    journalIdField.setValue(e.target.value);
    onChange(e.target.value);
  };

  const renderValue = (journalId:string|Array<string>) => {
    let selectedJournals: Array<ApiJournal> = [];

    if (multiple) {
      const matchedJournals: Array<ApiJournal> = (journalId as Array<string>)
        .map((id:string) => journals.find((j) => j.id === id))
        .filter((journal) => journal !== undefined) as Array<ApiJournal>; // will not return undefined journals
      selectedJournals = matchedJournals;
    } else {
      const matchedJournal = journals.find((j) => j.id === journalId);
      if (matchedJournal !== undefined) selectedJournals.push(matchedJournal);
    }

    return (
      <MenuItem
        sx={{
          p: 1,
        }}
      >
        {selectedJournals.length === 0 && (
          <span>
            test
            {multiple ? 'Any' : 'Select Journal'}
          </span>
        )}
        {selectedJournals.length === 1 && <JournalSelectOptionJournal journal={selectedJournals[0]} />}
        {selectedJournals.length > 1 && (
          <Stack direction="row" gap={1} flexWrap="wrap">
            {selectedJournals.map((j) => (
              <Chip
                avatar={
                  <JournalAvatar journal={j} hideBadge sx={{ width: 24, height: 24 }} />
                                      }
                variant="outlined"
                sx={{ borderColor: j.colour, color: j.colour }}
                key={j.id}
                label={j.title}
              />
            ))}
          </Stack>
        )}
      </MenuItem>
    );
  };

  const getCastedFieldValue = (): string|Array<string> => (
    multiple ? journalIdField.value as Array<string> : journalIdField.value as string);

  const getInputProps = ():InputBaseComponentProps => {
    if (
      (multiple && ((journalIdField.value as Array<string>).length > 0))
        || (!multiple && (journalIdField.value as string))
    ) {
      return { sx: { p: 0 } };
    }

    return {};
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="journal-select-label">Journal</InputLabel>
      <Select
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        labelId="journal-select-label"
        value={getCastedFieldValue()}
        label="Journal"
        multiple={multiple}
        error={journalIdField.isTouched && !journalIdField.isValid}
        renderValue={renderValue}
        onChange={onSelectChange}
        inputProps={getInputProps()}
        MenuProps={{
          MenuListProps: { sx: { p: 0 } },
        }}
      >
        {journals.map((journal) => (
          <MenuItem
            key={journal.id}
            value={journal.id}
            sx={{
              p: 1,
            }}
          >
            {multiple && <Checkbox checked={selectedJournalId.indexOf(journal.id as string) >= 0} />}
            <JournalSelectOptionJournal journal={journal} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(JournalSelect);
