import { DEFAULT_DATE_FORMAT } from 'journals-constants';
import moment from 'moment';
import { ApiJournalEntry } from 'types/api';
import { IFormFieldDefinitions } from 'types/useForm';

const DEFAULT_ENTRY_TITLE: string = '';

const getDefaultEntryForm = (entry?: ApiJournalEntry, journalId?: string): IFormFieldDefinitions => ({
  journalIdField: {
    label: 'Journal',
    defaultValue: entry ? entry.journalId : journalId,
    validationRules: {
      isRequired: true,
    },
  },
  dateField: {
    label: 'Date',
    defaultValue: entry ? moment.utc(entry.date, 'YYYY-MM-DDTHH:mm:SS') : moment.utc(),
    validationRules: {
      isRequired: true,
      rules: [
        {
          validateFn: (value) => moment(value as string, DEFAULT_DATE_FORMAT, true).isValid(),
          invalidMsg: 'Date is in incorrect format',
        },
      ],
    },
  },
  titleField: {
    label: 'Title',
    defaultValue: entry ? entry.title : DEFAULT_ENTRY_TITLE,
    validationRules: {
      isRequired: true,
    },
  },
  editorContentField: {
    defaultValue: entry ? JSON.parse(entry.content) : {},
  },
  tagsField: {
    defaultValue: entry ? entry.tags.map((tag) => tag.tag) : [],
  },
  attachmentsField: {
    defaultValue: entry ? entry.attachments : [],
  },
});

// eslint-disable-next-line import/prefer-default-export
export { getDefaultEntryForm };
