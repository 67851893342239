import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  AlertTitle,
  Button,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { generateRouteWithId } from 'utils/route-utils';
import useApiFetch from 'hooks/use-api-fetch';
import { ApiIdentifyableEntity } from 'types/api';
import { apiDeleteFn } from 'hooks/use-api-fetch/types';
import { DEFAULT_ICONS } from 'journals-constants';
import { LoadingBackdrop, ConfirmDialog, PageButton } from '..';
import { JournalsRoute } from '../../types/routing';
import { DataListConfirmDeleteState, DataListMenuItem, ParsedPageResult } from './types';

interface IProps<T extends ApiIdentifyableEntity> {
    dataList?: T[];
    isLoading?: boolean;
    error?: string;
    entitySingular: string;
    entityPlural: string;
    newEntityRoute: JournalsRoute;
    editEntityRoute: JournalsRoute;
    apiDelete?: apiDeleteFn;
    apiDeleteEndpoint: string;
    renderListItem: (
        dataEntity: T,
        menuAction: React.ReactElement
    ) => React.ReactElement;
    additionalMenuItems?: DataListMenuItem[];
    paging?: boolean;
    pageSize?: number;
    dataUrlFn?: (page: number) => string;
    parsePagedResultFn?: (result: unknown) => ParsedPageResult<T>;
    refreshList?: boolean;
    menuEditEnabledFn: (entity: T) => boolean;
    menuDeleteEnabledFn: (entity: T) => boolean;
    additionalMenuItemsEnabledFn?: (entity: T) => { [name: string]: boolean };
}

// If children is needed, IProps needs to be wrapped with PropsWithChildren.
const DataList = <T extends ApiIdentifyableEntity>({
  dataList,
  isLoading,
  error,
  entitySingular,
  entityPlural,
  newEntityRoute,
  editEntityRoute,
  apiDelete,
  apiDeleteEndpoint,
  renderListItem,
  additionalMenuItems,
  paging,
  pageSize,
  dataUrlFn,
  parsePagedResultFn,
  refreshList,
  menuEditEnabledFn,
  menuDeleteEnabledFn,
  additionalMenuItemsEnabledFn,
}: IProps<T>) => {
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(
    paging ? 0 : dataList?.length || 0,
  );
  const {
    data: pagedResult,
    isLoading: isPagedRequestLoading,
    error: pagedRequestError,
    apiGet,
  } = useApiFetch();

  const [data, setData] = useState<T[]>([]);

  useEffect(() => {
    if (!pagedResult) return;

    if (!parsePagedResultFn) throw new Error('parsePagedResultFn must be configured.');

    const { parsedData, parsedPage, parsedTotalCount } = parsePagedResultFn(pagedResult);
    setData(parsedPage === 1 ? parsedData : [...data, ...parsedData]);
    setPage(parsedPage);
    setTotalCount(parsedTotalCount);
  }, [pagedResult]);

  useEffect(() => {
    if (!dataList) return;
    setData(dataList);
  }, [dataList]);

  useEffect(() => {
    if (!paging) return;
    if (!dataUrlFn) throw new Error('dataUrlFn must be configured.');

    setPage(1);
    apiGet(dataUrlFn(1));
  }, [refreshList]);

  const loadMore = async () => {
    if (!dataUrlFn) throw new Error('dataUrlFn must be configured.');
    await apiGet(dataUrlFn(page + 1));
    setPage(page + 1);
  };

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement>();
  const [menuEditEnabled, setMenuEditEnabled] = useState<boolean>(true);
  const [menuDeleteEnabled, setMenuDeleteEnabled] = useState<boolean>(true);
  const [additionalMenuItemsEnabled, setAdditionalMenuItemsEnabled] = useState<{[name: string]: boolean;}>(
    additionalMenuItems
      ? additionalMenuItems.reduce(
        (a, v) => ({ [v.text]: false }),
        {},
      )
      : [],
  );
  const [confirmDeleteState, setConfirmDeleteState] = useState<DataListConfirmDeleteState>({
    isOpen: false,
    entityId: undefined,
  });

  const isMenuOpen = Boolean(menuAnchorElement);

  const navigate = useNavigate();

  const getEntityId = (menuAction: HTMLElement): string => menuAction.id.substring(9);

  const openMenu = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    setMenuAnchorElement(e.currentTarget);

    const actionedEntity: T | undefined = data.find(
      (x) => x.id === getEntityId(e.currentTarget),
    );

    if (actionedEntity === undefined) return;

    setMenuEditEnabled(
      !menuEditEnabledFn || menuEditEnabledFn(actionedEntity),
    );
    setMenuDeleteEnabled(
      !menuDeleteEnabledFn || menuDeleteEnabledFn(actionedEntity),
    );
    if (additionalMenuItemsEnabledFn) {
      setAdditionalMenuItemsEnabled(additionalMenuItemsEnabledFn(actionedEntity));
    }
  };

  const getMenuActionId = (): string => {
    if (!menuAnchorElement) return '';
    return menuAnchorElement !== undefined ? getEntityId(menuAnchorElement) : '';
  };

  const handleEdit = (): void => {
    navigate(generateRouteWithId(editEntityRoute, getMenuActionId()));
    setMenuAnchorElement(undefined);
  };

  const handleDelete = (): void => {
    setConfirmDeleteState({
      isOpen: true,
      entityId: getMenuActionId(),
    });
    setMenuAnchorElement(undefined);
  };

  const onCancelDelete = (): void => {
    setConfirmDeleteState({
      isOpen: false,
      entityId: undefined,
    });
  };

  const onConfirmDelete = async (): Promise<void> => {
    if (!confirmDeleteState.entityId) return;
    if (!apiDelete) throw new Error('apiDelete is not configured.');
    await apiDelete(apiDeleteEndpoint, confirmDeleteState.entityId);
    onCancelDelete();
  };

  const handleAdditionalMenuAction = (
    actionFn: (actionId: string) => void,
  ): void => {
    actionFn(getMenuActionId());
    setMenuAnchorElement(undefined);
  };

  if (error || pagedRequestError) {
    return (
      <Alert severity="error" sx={{ mt: 1 }}>
        <AlertTitle>Error</AlertTitle>
        {`There was an error retreiving your ${entityPlural}. Please try again later.`}
      </Alert>
    );
  }

  if (isLoading || (isPagedRequestLoading && (!data || data.length === 0))) { return <LoadingBackdrop />; }

  if (data && data.length === 0) {
    return (
      <Alert
        sx={{ mt: 1 }}
        severity="warning"
        action={(
          <Button
            color="inherit"
            size="small"
            onClick={() => navigate(newEntityRoute.path)}
          >
            {`Create New ${entitySingular}`}
          </Button>
        )}
      >
        <AlertTitle>
          {`No ${entityPlural} Found`}
        </AlertTitle>
        {`No ${entityPlural} were found.`}
      </Alert>
    );
  }

  const dataEntityMenu = (
    <Menu
      open={isMenuOpen}
      anchorEl={menuAnchorElement}
      onClose={() => setMenuAnchorElement(undefined)}
    >
      {additionalMenuItems
                && additionalMenuItems.map(
                  (action) => additionalMenuItemsEnabled[action.text] && (
                    <MenuItem
                      key={action.text}
                      onClick={() => handleAdditionalMenuAction(action.actionFn)}
                    >
                      <ListItemIcon>{action.icon}</ListItemIcon>
                      <ListItemText>{action.text}</ListItemText>
                    </MenuItem>
                  ),
                )}
      {menuEditEnabled && (
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <DEFAULT_ICONS.Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Edit
            {' '}
            {entitySingular}
          </ListItemText>
        </MenuItem>
      )}
      {menuDeleteEnabled && (
        <MenuItem
          onClick={handleDelete}
          sx={{
            color: (theme) => theme.palette.error.main,
          }}
        >
          <ListItemIcon>
            <DEFAULT_ICONS.Delete fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );

  if (data && data.length > 0) {
    return (
      <>
        <PageButton onClick={() => navigate(newEntityRoute.path)} />
        <ConfirmDialog
          open={confirmDeleteState.isOpen}
          title={`Delete ${entitySingular}`}
          message={`Are you sure you want to delete this ${entitySingular.toLowerCase()}?`}
          confirmText="Delete"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
          isDangerConfirm
        />
        {dataEntityMenu}

        <List>
          <InfiniteScroll
            dataLength={data.length}
            next={loadMore}
            hasMore={
                            (paging || false)
                            && (pageSize || 10) * page < totalCount
                        }
            loader={<em>Loading...</em>}
          >
            {data.map((dataEntity) => renderListItem(
              dataEntity,
              <IconButton
                className="open-menu-button"
                onClick={openMenu}
                id={`openMenu_${dataEntity.id}`}
              >
                <MenuIcon />
              </IconButton>,
            ))}
          </InfiniteScroll>
        </List>
      </>
    );
  }

  return <> </>;
};

export default DataList;
