import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LoadingIcon from '@mui/icons-material/Timer';

const MenuAuth: React.FC = (): JSX.Element => {
  const {
    user,
    isLoading: isAuthLoading,
    isAuthenticated,
    logout,
  } = useAuth0();

  if (isAuthLoading) {
    return (
      <ListItem>
        <ListItemIcon>
          <LoadingIcon />
        </ListItemIcon>
        <ListItemText primary="Loading" />
      </ListItem>
    );
  }

  if (isAuthenticated) {
    return (
      <ListItemButton
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText
          primary="Logout"
          secondary={`Logged in as ${user!.name}`}
        />
      </ListItemButton>
    );
  }

  return <> </>;
};

export default MenuAuth;
