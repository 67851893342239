import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useMemo, useState } from 'react';
import useApiFetch from 'hooks/use-api-fetch';
import { ApiEndpoint } from 'enums/api';
import {
  IJournalsContextState,
  IUser,
} from '../types/context';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <JournalsContext>.');
};

const initialState: IJournalsContextState = {
  setLastJournalId: stub,
};

const JournalsContext = React.createContext<IJournalsContextState>(initialState);

const JournalsContextProvider: React.FC = ({ children }) => {
  const { user: authenticatedUser } = useAuth0();
  const [user, setUser] = useState(initialState.user);
  const [lastJournalId, setLastJournalIdLocal] = useState(
    initialState.lastJournalId,
  );
  const [userSynced, setUserSynced] = useState(false);

  const { apiPost } = useApiFetch();

  /**
     * Sets the user from the authenticated user from Auth0.
     */
  const setParsedUser = (): void => {
    setUser({
      name: authenticatedUser!.name,
      email: authenticatedUser!.email,
      avatar: authenticatedUser!.picture,
    } as IUser);
  };

  useEffect(() => {
    if (!authenticatedUser) return;
    if (user && user.email === authenticatedUser.email) return;

    if (!userSynced) {
      apiPost(ApiEndpoint.SyncJournalUser, null);
      setUserSynced(true);
    }

    setParsedUser();
  }, [authenticatedUser]);

  /**
     * Sets the last journal ID used.
     * @param journalId The last journal id used.
     */
  const setLastJournalId = (journalId: string): void => {
    if (journalId !== lastJournalId) setLastJournalIdLocal(journalId);
  };

  const ctx: IJournalsContextState = useMemo(
    () => ({ user, lastJournalId, setLastJournalId }),
    [user, lastJournalId, setLastJournalId],
  );

  return (
    <JournalsContext.Provider value={ctx}>
      {children}
    </JournalsContext.Provider>
  );
};

export { JournalsContext, JournalsContextProvider };
