import { useContext } from 'react';
import { JournalsContext } from 'context/JournalsContext';
import { ApiPermission, ApiJournal, ApiJournalEntry } from 'types/api';

interface IUsePermissions {
    isEntryEditAllowed: (entry: ApiJournalEntry) => boolean;
    isEntryDeleteAllowed: (entry: ApiJournalEntry) => boolean;
    isEntryCreateAllowed: (journal: ApiJournal) => boolean;
    isJournalEditAllowed: (journal: ApiJournal) => boolean;
    isJournalDeleteAllowed: (journal: ApiJournal) => boolean;
}

const usePermissions = (): IUsePermissions => {
  const { user } = useContext(JournalsContext);

  const getJournalPermission = (journal: ApiJournal): ApiPermission => (
    journal?.permissions?.find((p) => p.user.email === user?.email)
            ?? ({
              journalId: journal.id,
              user,
              createEntry: false,
              editAllEntries: false,
              deleteAllEntries: false,
              editOwnEntries: false,
              deleteOwnEntries: false,
            } as ApiPermission)
  );

  const isEntryEditAllowed = (entry: ApiJournalEntry): boolean => {
    if (entry.journal === undefined) return false;
    const permission = getJournalPermission(entry.journal);
    return (
      permission.editAllEntries
            || (permission.editOwnEntries && entry.owner?.email === user?.email)
    );
  };

  const isEntryDeleteAllowed = (entry: ApiJournalEntry): boolean => {
    if (entry.journal === undefined) return false;
    const permission = getJournalPermission(entry.journal);
    return (
      permission.deleteAllEntries
            || (permission.deleteOwnEntries && entry.owner?.email === user?.email)
    );
  };

  const isEntryCreateAllowed = (journal: ApiJournal): boolean => {
    const permission = getJournalPermission(journal);
    return permission.createEntry;
  };

  const isJournalEditAllowed = (journal: ApiJournal): boolean => journal?.owner?.email === user?.email;

  const isJournalDeleteAllowed = (journal: ApiJournal): boolean => journal?.owner?.email === user?.email;

  return {
    isEntryEditAllowed,
    isEntryDeleteAllowed,
    isEntryCreateAllowed,
    isJournalEditAllowed,
    isJournalDeleteAllowed,
  };
};

export default usePermissions;
