const generateFormDataFromFiles = async (files: File[]) => {
  // Because of a chrome bug, we need to clone the file to be able to upload from google drive from phone.
  // What we need is await file.arrayBuffer()
  const formData = new FormData();
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    // eslint-disable-next-line no-await-in-loop
    const buffer = await file.arrayBuffer();
    const clone = new File([buffer], file.name, { type: file.type });
    formData.append('formFile', clone);
    formData.append('fileType', clone.type);
    formData.append('fileName', clone.name);
  }

  return formData;
};

// eslint-disable-next-line import/prefer-default-export
export { generateFormDataFromFiles };
