import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useApiFetch from 'hooks/use-api-fetch';
import { LoadingBackdrop } from 'components';
import { JOURNALS_ROUTE_COLLECTION } from 'journals-constants';
import { ApiJournal } from 'types/api';
import { getApiUrl } from 'utils/api-utils';
import { ApiEndpoint } from 'enums/api';
import { JournalForm } from '../..';

const EditJournal: React.FC = (): React.ReactElement => {
  const params = useParams();
  const navigate = useNavigate();

  const goToJournalList = (): void => {
    navigate(JOURNALS_ROUTE_COLLECTION.EditJournal.path);
  };

  if (!params.journalId) {
    goToJournalList();
  }

  const { isLoading, data: journal, error } = useApiFetch(getApiUrl(ApiEndpoint.Journal, params.journalId), true);

  if (!isLoading && error !== undefined) {
    goToJournalList();
  }

  if (isLoading || journal === undefined) {
    return <LoadingBackdrop />;
  }

  return <JournalForm journal={journal as ApiJournal} />;
};

export default EditJournal;
