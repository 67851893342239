import { useEffect, useRef, useState } from 'react';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { getEditorTools } from 'utils/editor-js-utils';
import useApiFetch from 'hooks/use-api-fetch';

const useEditor = (
  holderId:string,
  defaultData: OutputData,
  onChange: (data: OutputData) => void,
  onReady: () => void,
  minHeight: number = 80,
) => {
  const editorInstance = useRef<EditorJS>();
  const [editorData, setEditorData] = useState(defaultData || {});
  const { apiPost } = useApiFetch();

  const initEditor = () => {
    const editor = new EditorJS({
      holder: holderId,
      data: editorData,
      inlineToolbar: true,
      minHeight,
      tools: getEditorTools(apiPost),
      placeholder: 'Write your entry!',
      onReady: () => {
        editorInstance.current = editor;
        if (onReady) onReady();
      },
      onChange: (api) => {
        api.saver.save().then((content) => {
          // Fixed issue with & being encoded and breaking google drive image link.
          const imagesWithAmp = content?.blocks?.filter(
            (block) => block.type === 'image' && block.data.file.url.includes('&amp;'),
          );
          imagesWithAmp.forEach((image) => {
            // eslint-disable-next-line no-param-reassign
            image.data.file.url = image.data.file.url.replace('&amp;', '&');
          });

          setEditorData(content);
        });
      },
    });
  };

  useEffect(() => {
    if (!editorInstance.current) {
      initEditor();
    }
    return () => {
      if (editorInstance.current) {
        editorInstance.current.destroy();
        editorInstance.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    if (onChange) onChange(editorData);
  }, [editorData]);

  return { editorData };
};

export default useEditor;
