// eslint-disable-next-line no-shadow
export enum ApiFetchActionType{
    StartRequest = 'api-request',
    RequestSuccess = 'fetch-data',
    RequestError = 'error',
  }

export interface ApiFetchAction{
    type: ApiFetchActionType,
    payload?: unknown
  }

export interface IApiFetchState {
    data?: unknown;
    isLoading: boolean;
    error?: string;
}

export interface IApiFetchOptions{
    abortSignal?: AbortSignal
}

export type apiGetFn = (
    endpoint: string,
    fetchOpts?: IApiFetchOptions
) => Promise<Response | undefined>;
export type apiPostFn = (
    endpoint: string,
    body: unknown,
    returnResponseObject?: boolean
) => Promise<Response | undefined>;
export type apiPutFn = (
    endpoint: string,
    id: string,
    body: unknown,
    returnResponseObject?: boolean
) => Promise<Response | undefined>;
export type apiDeleteFn = (
    endpoint: string,
    id: string,
    returnResponseObject?: boolean
) => Promise<Response | undefined>;
export type refreshDataFn = () => Promise<Response | undefined>;

export interface IApiFetch extends IApiFetchState {
    apiGet: apiGetFn;
    apiPost: apiPostFn;
    apiPut: apiPutFn;
    apiDelete: apiDeleteFn;
    refreshData: refreshDataFn;
}
