import { Chip, Stack } from '@mui/material';
import React from 'react';
import { IFilterChip } from '../../types';

interface IProps{
    filterChips: Array<IFilterChip>,
    onChipDelete: (chip: IFilterChip) => void
}

const EntriesFilterChips: React.FC<IProps> = ({ filterChips, onChipDelete }) => (
  <Stack direction="row" gap={1} flexWrap="wrap">
    {filterChips && filterChips.map((chip) => (
      <Chip
        key={chip.field}
        label={(
          <>
            {`${chip.label}: `}
            <strong>
              {chip.value}
            </strong>
          </>
                    )}
        onDelete={() => onChipDelete(chip)}
      />
    ))}

  </Stack>
);

export default EntriesFilterChips;
