/* eslint-disable react/no-danger */
import { OutputData } from '@editorjs/editorjs';
import { Box, SxProps, Theme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { parseEditorJsData } from 'utils/editor-js-utils';

interface IProps{
    editorData: OutputData,
    style?:SxProps<Theme>
}

const ParsedEditorData: React.FC<IProps> = ({ editorData, style }) => {
  const [reactElement, setReactElement] = useState<string>('');

  useEffect(() => {
    setReactElement(parseEditorJsData(editorData));
  }, [editorData]);

  const defaultStyle = {
    overflow: 'auto',
    fontFamily: '"Roboto"',
  };

  const boxStyle = { ...defaultStyle, ...style };

  return (
    <Box className="parsed-editor" sx={boxStyle}>
      {reactElement && (<div dangerouslySetInnerHTML={{ __html: reactElement }} />)}
    </Box>
  );
};

export default ParsedEditorData;
