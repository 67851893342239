import {
  Container, Link, Typography, Box,
} from '@mui/material';
import React from 'react';
import { APP_DETAILS } from 'journals-constants';

const Footer: React.FC = () => {
  const renderCopyright = (): JSX.Element => (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link
        color="inherit"
        href="https://journals.unclebenportal.net/"
      >
        {APP_DETAILS.TITLE}
      </Link>
      {' '}
      {new Date().getFullYear()}
      .
    </Typography>
  );

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) => (theme.palette.mode === 'light'
          ? theme.palette.grey[200]
          : theme.palette.grey[800]),
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          textAlign: 'center',
        }}
      >
        {renderCopyright()}
      </Container>
    </Box>
  );
};

export default Footer;
