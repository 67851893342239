import React from 'react';
import {
  ArrowRight,
  ListRounded,
  LibraryBooksRounded,
  AddCircleRounded,
  PostAddRounded,
  Info,
  AdminPanelSettings,
} from '@mui/icons-material';
import { APP_DETAILS } from '.';
import { JournalsRouteCollection } from '../types/routing';

const JOURNALS_ROUTE_COLLECTION: JournalsRouteCollection = {
  Home: {
    path: '/',
    title: APP_DETAILS.TITLE,
    icon: <ArrowRight />,
  },
  JournalList: {
    path: '/journals',
    title: 'All Journals',
    icon: <LibraryBooksRounded />,
  },
  NewJournal: {
    path: '/journals/new',
    title: 'New Journal',
    icon: <AddCircleRounded />,
  },
  EditJournal: {
    path: '/journals/:{0}',
    pathForRoute: '/journals/:journalId',
    title: 'Edit Journal',
    icon: <ArrowRight />,
  },
  EntryList: {
    path: '/entries',
    title: 'Entries',
    icon: <ListRounded />,
  },
  NewEntry: {
    path: '/entries/new',
    title: 'New Entry',
    icon: <PostAddRounded />,
  },
  EditEntry: {
    path: '/entries/:{0}',
    pathForRoute: '/entries/:entryId',
    title: 'Edit Entry',
    icon: <ArrowRight />,
  },
  VersionHistory: {
    path: '/version-history',
    title: 'Version History',
    icon: <Info />,
  },
  EditJournalPermissions: {
    path: '/journals/permissions/:{0}',
    pathForRoute: '/journals/permissions/:journalId',
    title: 'Journal Permissions',
    icon: <AdminPanelSettings />,
  },
};

export default JOURNALS_ROUTE_COLLECTION;
