import { LoadingBackdrop } from 'components';
import { ApiEndpoint } from 'enums/api';
import useApiFetch from 'hooks/use-api-fetch';
import { JOURNALS_ROUTE_COLLECTION } from 'journals-constants';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiJournalEntry } from 'types/api';
import { getApiUrl } from 'utils/api-utils';
import EntryForm from '../entry-form/EntryForm';

const EditEntry = () => {
  const { entryId } = useParams();
  const navigate = useNavigate();

  const goToJournalEntryList = () => {
    navigate(JOURNALS_ROUTE_COLLECTION.JournalEntryList.path);
  };

  if (!entryId) {
    goToJournalEntryList();
  }

  const {
    isLoading, data: journalEntry, error,
  } = useApiFetch(getApiUrl(ApiEndpoint.Entry, entryId), true);

  if (!isLoading && error !== undefined) {
    goToJournalEntryList();
  }

  if (isLoading || journalEntry === undefined) {
    return <LoadingBackdrop />;
  }

  return <EntryForm entry={journalEntry as ApiJournalEntry} />;
};

export default EditEntry;
