import { Alert, AlertTitle, Stack } from '@mui/material';
import LoadingBox from 'components/loading-box/LoadingBox';
import { ApiEndpoint } from 'enums/api';
import useApiFetch from 'hooks/use-api-fetch';
import { IApiFetchOptions } from 'hooks/use-api-fetch/types';
import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import { getApiUrl } from 'utils/api-utils';

interface IProps{
  attachmentId: string;
    src:string;
}

const VideoPlayer: React.FC<IProps> = ({ attachmentId, src }) => {
  if (!src) return <> </>;

  if (src.startsWith('https://drive.google.com/file')) {
    return (
      <Stack
        className="video-iframe-container"
      >
        <iframe className="video-iframe" src={src} title="Video" allowFullScreen />
      </Stack>
    );
  }

  const {
    data, isLoading, error, apiGet,
  } = useApiFetch();

  useEffect(() => {
    const abortCtrl = new AbortController();
    const opts: IApiFetchOptions = { abortSignal: abortCtrl.signal };
    apiGet(getApiUrl(ApiEndpoint.GetAttachmentVideoUrl, attachmentId), opts);
    return () => abortCtrl.abort();
  }, [attachmentId]);

  return (
    <Stack style={{ alignSelf: 'center', width: '100%', height: '100%' }}>
      {isLoading && <LoadingBox />}
      {!isLoading && error && (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        There was an error loading this video. Please make sure your entry is saved.
      </Alert>
      )}
      {!isLoading && data
      && <ReactPlayer width="100%" height="100%" url={(data as {url:string}).url} controls />}
    </Stack>
  );
};

export default VideoPlayer;
