import React from 'react';
import { Avatar } from '@mui/material';

const APP_DETAILS = {
  TITLE: 'Journals',
  ICON: (
    <Avatar
      sx={{ width: '30px', height: '30px' }}
      variant="square"
      alt="Journals"
      src={`${process.env.PUBLIC_URL}/logo192-white.png`}
    />
  ),
};

const ATTACHMENT_TYPES = {
  VIDEO: 'Video',
  IMAGE: 'Image',
};

const DEFAULT_FIELD_WIDTH = '330px';
const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export {
  APP_DETAILS,
  DEFAULT_FIELD_WIDTH,
  DEFAULT_DATE_FORMAT,
  ATTACHMENT_TYPES,
};
