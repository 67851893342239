/* eslint-disable no-shadow */
enum ApiEndpoint{
    UploadAvatar = '/upload/avatar',
    UploadAttachments = '/upload/attachments',
    UploadEditorImage = '/upload/editor/image',
    Journal = '/journal',
    Entry = '/entry',
    GetTags = '/tags',
    FilterEntries = '/entry/filter',
    EntryOperations = '/operation/entry',
    SyncJournalUser = '/journalUser/sync',
    Permission = '/permission',
    GetAttachmentVideoUrl = '/media/attachmentVideoUrl'
}

// eslint-disable-next-line import/prefer-default-export
export { ApiEndpoint };
