import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const ProgressWheel = () => (
  <Box sx={{ width: '100%' }} textAlign="center">
    {/* TODO color was 'loading'. but I couldn't extend module to have loading here. */}
    <CircularProgress color="primary" />
  </Box>
);

export default ProgressWheel;
