// eslint-disable-next-line no-shadow
export enum ChangeType{
    Added,
    Updated,
    Deleted,
    Fixed
  }

export interface VersionChange{
    type: ChangeType;
    text: string;
  }

export interface VersionChanges{
      version: string,
      releaseDate: string,
      changes: VersionChange[]
  }
