/* eslint-disable jsx-a11y/label-has-associated-control */
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { LoadingButton } from '@mui/lab';
import {
  Input,
  List, ListSubheader,
  Stack,
  Typography,
} from '@mui/material';
import { ApiEndpoint } from 'enums/api';
import useApiFetch from 'hooks/use-api-fetch';
import { ATTACHMENT_TYPES } from 'journals-constants';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { ApiAttachment } from 'types/api';
import { generateFormDataFromFiles } from 'utils/attachment-utils';
import AttachmentListItem from './AttachmentListItem';
import MediaModal, { MediaModalEntry, MediaType } from '../media-modal/MediaModal';

interface IProps{
    attachmentList: Array<ApiAttachment>,
    isEditMode?: boolean,
    hideTitle?: boolean,
    hideShadow?: boolean,
    onChange?: (attachmentList: ApiAttachment[]) => void
}

const AttachmentList: React.FC<IProps> = ({
  attachmentList, isEditMode, hideTitle, hideShadow, onChange,
}) => {
  const {
    isLoading: isUploading,
    error: uploadingError,
    data: uploadedResponse,
    apiPost: apiUploadFile,
  } = useApiFetch();

  const { enqueueSnackbar } = useSnackbar();

  // const [attachments, setAttachments] = useState<ApiAttachment[]>(attachmentList);
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const [mediaModalActiveIndex, setMediaModalActiveIndex] = useState(0);

  const saveFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const files = Array.from(e.target.files);

    if (files.length === 0) return;

    if (files.find((file) => file.size > 314572800)) {
      // 300MB
      enqueueSnackbar('Files cannot be larger than 300MB', { variant: 'error' });
      return;
    }

    const formData = await generateFormDataFromFiles(files);
    await apiUploadFile(ApiEndpoint.UploadAttachments, formData);
  };

  const setAttachments = (attachments: ApiAttachment[]) => {
    if (onChange) onChange(attachments);
  };

  const deleteAttachment = (source:string) => {
    setAttachments(attachmentList.filter((x) => x.source !== source));
  };

  const getMediaType = (attachment:ApiAttachment): MediaType|undefined => {
    switch (attachment.type) {
      case ATTACHMENT_TYPES.VIDEO:
        return MediaType.Video;
      case ATTACHMENT_TYPES.IMAGE:
        return MediaType.Image;
      default: return undefined;
    }
  };

  const getMediaAttachments = ():ApiAttachment[] => {
    if (!attachmentList || attachmentList.length === 0) return [];
    return attachmentList.filter((a) => a.type === ATTACHMENT_TYPES.IMAGE || a.type === ATTACHMENT_TYPES.VIDEO);
  };

  const getMediaEntries = (): MediaModalEntry[] => getMediaAttachments()
    .map((a) => ({
      attachmentId: a.id!,
      name: a.name,
      source: a.source,
      type: getMediaType(a),
    } as MediaModalEntry));

  const isMediaModalAvailable = ():boolean => getMediaAttachments().length > 0;

  const openAttachment = (attachment:ApiAttachment) => {
    if (attachment.type !== ATTACHMENT_TYPES.VIDEO && attachment.type !== ATTACHMENT_TYPES.IMAGE) {
      window.open(attachment.source);
      return;
    }

    setMediaModalActiveIndex(getMediaAttachments().findIndex((a) => a.source === attachment.source));
    setMediaModalOpen(true);
  };

  useEffect(() => {
    if (!isUploading && uploadingError === undefined && uploadedResponse !== undefined) {
      const parsedResp = uploadedResponse as ApiAttachment[];
      const newAttachments = [
        ...attachmentList.filter((x) => !parsedResp.find((a:ApiAttachment) => a.name === x.name)),
        ...parsedResp,
      ];
      setAttachments(newAttachments);
    }
  }, [isUploading, uploadingError, uploadedResponse]);

  useEffect(() => {
    if (uploadingError) { enqueueSnackbar('Error uploading attachment. Please try again later.', { variant: 'error' }); }
  }, [uploadingError]);

  if (!isEditMode && (!attachmentList || attachmentList.length === 0)) return <> </>;

  return (
    <>
      {isMediaModalAvailable()
      && (
      <MediaModal
        mediaEntries={getMediaEntries()}
        open={mediaModalOpen}
        selectedIndex={mediaModalActiveIndex}
        onClose={() => setMediaModalOpen(false)}
      />
      )}
      <Stack
        direction="column"
        alignItems="center"
        sx={{ boxShadow: (theme) => (hideShadow ? 'none' : theme.shadows[5]) }}
      >
        <List
          sx={{ width: '100%', py: 0 }}
          subheader={(
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                px: 1,
                backgroundColor: (theme) => theme.palette.grey[100],
              }}
            >
              {!hideTitle && (
              <ListSubheader sx={{ backgroundColor: 'inherit' }}>
                <Typography variant="body2" sx={{ my: 2 }}>
                  {attachmentList && attachmentList.length > 0 ? 'Attachments' : 'No attachments yet'}
                </Typography>
              </ListSubheader>
              )}
              {isEditMode && (
              <div>
                <label htmlFor="upload-attachment-button">
                  <Input
                    id="upload-attachment-button"
                    inputProps={{ multiple: true }}
                    type="file"
                    sx={{ display: 'none' }}
                    onChange={saveFiles}
                    disabled={isUploading}
                  />
                  <LoadingButton
                    variant="outlined"
                    component="span"
                    loading={isUploading}
                    endIcon={<UploadFileIcon />}
                    loadingPosition="end"
                  >
                    Upload
                  </LoadingButton>
                </label>
              </div>
              )}
            </Stack>
                      )}
        >
          {attachmentList
                        && attachmentList.length > 0
                        && attachmentList.map((attachment) => (
                          <AttachmentListItem
                            key={attachment.source}
                            attachment={attachment}
                            isEditMode={isEditMode ?? false}
                            onClick={() => openAttachment(attachment)}
                            onDelete={() => deleteAttachment(attachment.source)}
                          />
                        ))}
        </List>
      </Stack>
    </>
  );
};

export default React.memo(AttachmentList);
