/* eslint-disable react/jsx-props-no-spreading */
import { DesktopDatePicker, LoadingButton, MobileDatePicker } from '@mui/lab';
import {
  Alert, AlertTitle, Box, Button, Container, Divider, Stack, TextField, Theme, useMediaQuery, useTheme,
} from '@mui/material';
import React, {
  useCallback, useEffect, useState, useContext,
} from 'react';
import SaveIcon from '@mui/icons-material/Save';
import SaveAndCloseIcon from '@mui/icons-material/SaveOutlined';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BackIcon from '@mui/icons-material/NavigateBefore';
import { useSnackbar } from 'notistack';
import {
  ApiJournalEntry, ApiAttachment, ApiTag, ApiJournal,
} from 'types/api';
import { JournalsContext } from 'context/JournalsContext';
import useForm from 'hooks/use-form';
import { DEFAULT_FIELD_WIDTH, JOURNALS_ROUTE_COLLECTION } from 'journals-constants';
import useApiFetch from 'hooks/use-api-fetch';
import { getDefaultEntryForm } from 'modules/edit-entry/utils';
import { generateRouteWithId } from 'utils/route-utils';
import { setCurrentTimeToDate } from 'utils/date-utils';
import { Moment } from 'moment';
import {
  AttachmentList,
  EditorJsEditor, JournalSelect, ProgressWheel, TagsInput,
} from 'components';
import { OutputData } from '@editorjs/editorjs';
import { getApiUrl } from 'utils/api-utils';
import { ApiEndpoint } from 'enums/api';

interface IProps{
    entry?: ApiJournalEntry
}

const EntryForm: React.FC<IProps> = ({ entry: inputEntry }) => {
  const [entry, setEntry] = useState(inputEntry);

  const [entryId] = useState<string|undefined>(entry ? entry.id : undefined);
  const [noJournalsError, setNoJournalsError] = useState<boolean>(false);
  const [editorReady, setEditorReady] = useState<boolean>(false);
  const [closeOnSave, setCloseOnSave] = useState<boolean>(false);

  const { lastJournalId, setLastJournalId } = useContext(JournalsContext);

  const [params] = useSearchParams();
  const journalId = params.get('journalId') || lastJournalId || undefined;

  const navigate = useNavigate();

  const form = useForm(getDefaultEntryForm(entry, journalId));

  const {
    journalIdField, dateField, titleField, editorContentField, tagsField, attachmentsField,
  } = form.fields;

  useEffect(() => {
    if (entry?.id) {
      form.refreshFormData(getDefaultEntryForm(entry, journalId));
    }
  }, [entry]);

  const {
    isLoading: isLoadingJournals,
    error: errorLoadingJournals,
    data: journalsWithCreateEntryPermission,
  } = useApiFetch(getApiUrl(ApiEndpoint.Journal, undefined, 'withCreateEntryPermission=true'), true);

  const {
    isLoading: isSaving,
    error: savingError,
    data: savedData,
    apiPost: apiPostJournal,
    apiPut: apiPutJournal,
  } = useApiFetch();

  const { enqueueSnackbar } = useSnackbar();
  const theme:Theme = useTheme();
  const isLargeVersion = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  useEffect(() => {
    if (savingError !== undefined) {
      enqueueSnackbar('Error while saving.', { variant: 'error' });
      return;
    }

    if (savedData !== undefined) {
      enqueueSnackbar('Entry saved.', { variant: 'success' });
      if (closeOnSave) navigate(JOURNALS_ROUTE_COLLECTION.EntryList.path);
      else if (entry) setEntry(savedData as ApiJournalEntry);
      else {
        console.log('navigating to edit', savedData);
        navigate(generateRouteWithId(JOURNALS_ROUTE_COLLECTION.EditEntry, (savedData as ApiJournalEntry).id!));
      }
    }
  }, [isSaving, savingError, savedData, closeOnSave]);

  useEffect(() => {
    if (!isLoadingJournals && errorLoadingJournals === undefined && journalsWithCreateEntryPermission === undefined) return;
    if (isLoadingJournals || errorLoadingJournals !== undefined) return;

    setNoJournalsError(
      journalsWithCreateEntryPermission === undefined || (journalsWithCreateEntryPermission as ApiJournal[]).length === 0,
    );
  }, [isLoadingJournals, errorLoadingJournals, journalsWithCreateEntryPermission]);

  const saveEntry = async (closeOnSuccess = false) => {
    setCloseOnSave(closeOnSuccess);

    const dataToSend: ApiJournalEntry = {
      journalId: journalIdField.value as string,
      title: titleField.value as string,
      date: setCurrentTimeToDate(dateField.value as Moment).format(),
      content: JSON.stringify(editorContentField.value),
      tags: (tagsField.value as Array<string>).map((tag) => ({ tag } as ApiTag)),
      attachments: attachmentsField.value as Array<ApiAttachment>,
    };

    if (entryId === undefined) {
      // POST
      await apiPostJournal(ApiEndpoint.Entry, dataToSend);
      setLastJournalId(journalIdField.value as string);
    } else {
      // PUT
      await apiPutJournal(ApiEndpoint.Entry, entryId, dataToSend);
    }
  };

  const saveAndClose = async () => {
    await saveEntry(true);
  };

  const onEditorValueChange = useCallback(
    (data) => {
      if (data !== editorContentField.value) editorContentField.setValue(data);
    },
    [editorContentField],
  );

  const onEditorReady = useCallback(() => setEditorReady(true), []);

  const onTagsChange = useCallback((newValue) => tagsField.setValue(newValue), [tagsField]);

  const onJournalChange = useCallback(
    (value) => {
      journalIdField.setValue(value);
    },
    [journalIdField],
  );

  return (
    <Container
      maxWidth="lg"
      sx={{
        px: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {noJournalsError && (
        <Alert severity="warning">
          <AlertTitle>Warning</AlertTitle>
          {' '}
          You have no journals yet. Create new journals first.
        </Alert>
      )}
      {errorLoadingJournals !== undefined && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {' '}
          Your journals could not be loaded. Please try again later
        </Alert>
      )}

      {!isLoadingJournals
                && !noJournalsError
                && errorLoadingJournals === undefined
                && journalsWithCreateEntryPermission !== undefined && (
                <>
                  <Stack direction={isLargeVersion ? 'row' : 'column'} spacing={5} sx={{ p: 0, m: 0 }}>
                    <Stack
                      direction="column"
                      spacing={5}
                      alignItems="center"
                      sx={{ width: DEFAULT_FIELD_WIDTH }}
                    >
                      <JournalSelect
                        autoOpen={journalIdField.value === undefined}
                        journals={journalsWithCreateEntryPermission as ApiJournal[]}
                        selectedJournalId={journalIdField.value as string ?? ''}
                        onChange={onJournalChange}
                      />

                      {isLargeVersion ? (
                        <DesktopDatePicker
                          label="Date"
                          inputFormat="DD/MM/yyyy"
                          value={dateField.value}
                          onChange={dateField.setValue}
                          renderInput={(parameters) => (
                            <TextField
                              fullWidth
                              {...parameters}
                              error={dateField.isTouched && !dateField.isValid}
                            />
                          )}
                        />
                      ) : (
                        <MobileDatePicker
                          label="Date"
                          inputFormat="DD/MM/yyyy"
                          value={dateField.value}
                          onChange={dateField.setValue}
                          renderInput={(parameters) => (
                            <TextField
                              fullWidth
                              {...parameters}
                              error={dateField.isTouched && !dateField.isValid}
                            />
                          )}
                        />
                      )}
                      {!editorReady && <ProgressWheel />}
                      {editorReady && (
                      <TextField
                        label="Title"
                        value={titleField.value || ''}
                        autoFocus={journalIdField.value !== undefined}
                        fullWidth
                        onChange={(e) => titleField.setValue(e.target.value)}
                        error={titleField.isTouched && !titleField.isValid}
                      />
                      )}
                    </Stack>
                    <Stack spacing={5} sx={{ maxWidth: DEFAULT_FIELD_WIDTH }}>
                      <TagsInput value={tagsField.value as Array<string>} onChange={onTagsChange} />
                      <Box sx={{ width: DEFAULT_FIELD_WIDTH }}>
                        <AttachmentList
                          attachmentList={attachmentsField.value as ApiAttachment[]}
                          isEditMode
                          onChange={(newAttachments) => attachmentsField.setValue(newAttachments)}
                        />
                      </Box>
                    </Stack>
                  </Stack>

                  <Stack spacing={5} sx={{ width: '100%' }} alignItems="center">
                    <Divider sx={{ mt: 3, width: '100%' }} />
                    <EditorJsEditor
                      defaultData={editorContentField.value as OutputData}
                      label="Entry content"
                      isExpandedVersion={isLargeVersion}
                      onChange={onEditorValueChange}
                      onReady={onEditorReady}
                    />
                    <Stack spacing={2}>
                      <Stack direction="column" gap={1} flexWrap="wrap">
                        <LoadingButton
                          variant="contained"
                          endIcon={<SaveIcon />}
                          onClick={() => saveEntry()}
                          sx={{ width: DEFAULT_FIELD_WIDTH }}
                          loading={isSaving}
                          loadingPosition="end"
                          disabled={!form.isValid}
                        >
                          Save entry
                        </LoadingButton>

                        <LoadingButton
                          variant="outlined"
                          endIcon={<SaveAndCloseIcon />}
                          onClick={saveAndClose}
                          sx={{ width: DEFAULT_FIELD_WIDTH }}
                          loading={isSaving}
                          loadingPosition="end"
                          disabled={!form.isValid}
                        >
                          Save &amp; Close
                        </LoadingButton>
                      </Stack>
                    </Stack>
                    <Box alignItems="start" sx={{ width: DEFAULT_FIELD_WIDTH }}>
                      <Button
                        onClick={() => navigate(JOURNALS_ROUTE_COLLECTION.EntryList.path)}
                        startIcon={<BackIcon />}
                      >
                        Back to Entries
                      </Button>
                    </Box>
                  </Stack>
                </>
      )}
    </Container>
  );
};
export default EntryForm;
