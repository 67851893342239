import { useAuth0 } from '@auth0/auth0-react';
import {
  Button, Container, Stack, Typography,
} from '@mui/material';
import React from 'react';

const Login: React.FC = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Container component="main" sx={{ mt: 4, mb: 2 }} maxWidth="xs">
      <Stack direction="column" spacing={5}>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>
          Journals
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={() => loginWithRedirect()}
        >
          Login
        </Button>
      </Stack>
    </Container>
  );
};

export default Login;
