import moment from 'moment';

/**
 * Returns the formatted local date/time based on a utc date.
 * @param utcDate The utc date.
 * @param includeTime Whether to include time in the output.
 * @returns The formatted local date/time.
 */
const formatFromUtc = (
  utcDate: moment.Moment,
  includeTime: Boolean = false,
): string => {
  const format = includeTime ? 'DD MMM YYYY HH:mm' : 'DD MMM YYYY';
  return moment.utc(utcDate).local().format(format);
};

/**
 * Retuns the passed utc date updated with the current utc time.
 * @param utcDate The utc date.
 * @returns The updated date.
 */
const setCurrentTimeToDate = (utcDate: moment.Moment): moment.Moment => {
  const nowUtc = moment.utc();
  const date = moment.utc(utcDate.format('YYYY-MM-DD'));
  date.set({
    hour: nowUtc.get('hour'),
    minute: nowUtc.get('minute'),
    second: nowUtc.get('second'),
  });
  return date;
};

export { formatFromUtc, setCurrentTimeToDate };
