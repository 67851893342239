import {
  Button,
  Checkbox, Container, FormControlLabel, FormGroup, Paper, Stack, TextField,
} from '@mui/material';
import { ConfirmDialog } from 'components';
import { ApiEndpoint } from 'enums/api';
import useApiFetch from 'hooks/use-api-fetch';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { ApiPermission, SavingStatus } from 'types/api';
import { getSavingStatus } from 'utils/api-utils';
import { IPermissionForm } from '../types';

interface IProps{
  journalId: string,
  permission?: ApiPermission,
    onSavingStatusUpdate?: (savingStatus: SavingStatus) => void,
}

const generateDefaultPermissionForm = (journalId:string): IPermissionForm => ({
  journalId,
  email: '',
  createEntry: false,
  editOwnEntries: false,
  deleteOwnEntries: false,
  editAllEntries: false,
  deleteAllEntries: false,
});

const generatePermissionForm = (journalId:string, permission?: ApiPermission): IPermissionForm => {
  if (!permission) return generateDefaultPermissionForm(journalId);
  return {
    journalId: permission.journalId,
    id: permission.id,
    email: permission.user.email,
    createEntry: permission.createEntry,
    editOwnEntries: permission.editOwnEntries,
    deleteOwnEntries: permission.deleteOwnEntries,
    editAllEntries: permission.editAllEntries,
    deleteAllEntries: permission.deleteAllEntries,
  };
};

const PermissionForm: React.FC<IProps> = ({ journalId, permission, onSavingStatusUpdate }) => {
  const [formPermission, setFormPermission] = useState<IPermissionForm>(generatePermissionForm(journalId, permission));
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);

  useEffect(() => {
    setFormPermission(generatePermissionForm(journalId, permission));
  }, [permission]);

  const {
    isLoading: isSaving,
    error: savingError,
    data: savedData,
    apiPost,
    apiPut,
    apiDelete,
  } = useApiFetch();

  const { enqueueSnackbar } = useSnackbar();

  const onSavePermission = async () => {
    if (formPermission.id) {
      // PUT
      await apiPut(ApiEndpoint.Permission, formPermission.id, formPermission);
    } else {
      // POST
      await apiPost(ApiEndpoint.Permission, formPermission);
    }
  };

  const onDeletePermission = async () => {
    await apiDelete(ApiEndpoint.Permission, formPermission.id!);
  };

  const onCancelEdit = () => {
    if (onSavingStatusUpdate) onSavingStatusUpdate(SavingStatus.Saved);
  };

  const onEmailChange = (email:string) => {
    if (email) { setFormPermission({ ...formPermission, email }); }
  };

  const onCreateEntryChange = (e: React.SyntheticEvent<Element, Event>) => {
    setFormPermission({ ...formPermission, createEntry: (e.target as HTMLInputElement).checked });
  };

  const onEditOwnEntriesChange = (e: React.SyntheticEvent<Element, Event>) => {
    const { checked } = (e.target as HTMLInputElement);
    setFormPermission({
      ...formPermission,
      editOwnEntries: checked,
      editAllEntries: checked ? formPermission.editAllEntries : false,
    });
  };

  const onDeleteOwnEntriesChange = (e: React.SyntheticEvent<Element, Event>) => {
    const { checked } = (e.target as HTMLInputElement);
    setFormPermission({
      ...formPermission,
      deleteOwnEntries: checked,
      deleteAllEntries: checked ? formPermission.deleteAllEntries : false,
    });
  };

  const onEditAllEntriesChange = (e: React.SyntheticEvent<Element, Event>) => {
    const { checked } = (e.target as HTMLInputElement);
    setFormPermission({
      ...formPermission,
      editOwnEntries: checked ? true : formPermission.editOwnEntries,
      editAllEntries: checked,
    });
  };

  const onDeleteAllEntriesChange = (e: React.SyntheticEvent<Element, Event>) => {
    const { checked } = (e.target as HTMLInputElement);
    setFormPermission({
      ...formPermission,
      deleteOwnEntries: checked ? true : formPermission.deleteOwnEntries,
      deleteAllEntries: checked,
    });
  };

  useEffect(() => {
    if (savingError) {
      enqueueSnackbar(savingError, { variant: 'error' });
    } else if (savedData) {
      enqueueSnackbar('Permission saved.', { variant: 'success' });
    }

    if (onSavingStatusUpdate) onSavingStatusUpdate(getSavingStatus(isSaving, savedData, savingError));
  }, [isSaving, savingError, savedData]);

  return (
    <>
      <ConfirmDialog
        open={confirmDeleteOpen}
        title={`Delete permission for ${permission?.user.name}`}
        message="Are you sure you want to delete this permission?"
        confirmText="Delete"
        onConfirm={onDeletePermission}
        onCancel={() => setConfirmDeleteOpen(false)}
        isDangerConfirm
      />

      <Container maxWidth="xs" sx={{ p: 0 }}>
        <Paper sx={{ p: 2 }}>
          <Stack spacing={5}>
            <TextField
              label="Email"
              fullWidth
              required
              value={formPermission.email}
              onChange={(e) => onEmailChange(e.target.value)}
            />

            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Read Entries" disabled />
              <FormControlLabel
                control={<Checkbox />}
                label="Create Entries"
                checked={formPermission.createEntry}
                value={formPermission.createEntry}
                onChange={onCreateEntryChange}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Edit Own Entries"
                checked={formPermission.editOwnEntries}
                value={formPermission.editOwnEntries}
                onChange={onEditOwnEntriesChange}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Delete Own Entries"
                checked={formPermission.deleteOwnEntries}
                value={formPermission.deleteOwnEntries}
                onChange={onDeleteOwnEntriesChange}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Edit All Entries"
                checked={formPermission.editAllEntries}
                value={formPermission.editAllEntries}
                onChange={onEditAllEntriesChange}
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Delete All Entries"
                checked={formPermission.deleteAllEntries}
                value={formPermission.deleteAllEntries}
                onChange={onDeleteAllEntriesChange}
              />
            </FormGroup>

            <Stack spacing={1}>
              <Button variant="contained" disabled={isSaving} onClick={onSavePermission}>
                {formPermission.id ? 'Update Permission' : 'Create Permission'}
              </Button>
              {formPermission.id
              && (
              <Button
                variant="contained"
                color="error"
                disabled={isSaving}
                onClick={() => setConfirmDeleteOpen(true)}
              >
                Delete Permission
              </Button>
              )}
              {formPermission.id
              && <Button variant="outlined" disabled={isSaving} onClick={onCancelEdit}>Cancel</Button>}
            </Stack>
          </Stack>
        </Paper>
      </Container>
    </>
  );
};

export default PermissionForm;
