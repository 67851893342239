import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { JOURNALS_ROUTE_COLLECTION } from 'journals-constants';

const LandingComponent = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(JOURNALS_ROUTE_COLLECTION.EntryList.path);
  }, []);

  return <> </>;
};

export default LandingComponent;
