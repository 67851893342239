/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import {
  Hidden,
  Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ApiPermission } from 'types/api';
import {
  Add, Edit, Delete, Visibility,
} from '@mui/icons-material';
import { LoadingBackdrop, UserAvatar } from 'components';

interface IProps{
  permissions: ApiPermission[];
  onSelect?: (permission:ApiPermission) => void;
  isLoading?:boolean;
}

const renderPermissionIcon = (icon: React.ReactElement, tooltip:string) => (<Tooltip title={tooltip}>{icon}</Tooltip>);

const renderPermissionIcons = (permission:ApiPermission): React.ReactElement => {
  const icons: React.ReactElement[] = [];

  icons.push(renderPermissionIcon(<Visibility color="primary" />, 'Can read entries.'));

  icons.push(renderPermissionIcon(
    <Add color={permission.createEntry ? 'primary' : 'disabled'} />,
    permission.createEntry ? 'Can create entries.' : 'Cannot create entries',
  ));

  icons.push(renderPermissionIcon(
    <Edit color={permission.editAllEntries ? 'primary' : permission.editOwnEntries ? 'warning' : 'disabled'} />,
    permission.editAllEntries ? 'Can edit all entries.' : permission.editOwnEntries ? 'Can edit own entries.' : 'Cannot edit entries.',
  ));

  icons.push(renderPermissionIcon(
    <Delete color={permission.deleteAllEntries ? 'primary' : permission.deleteOwnEntries ? 'warning' : 'disabled'} />,
    permission.deleteAllEntries
      ? 'Can delete all entries.'
      : permission.deleteOwnEntries ? 'Can delete own entries.' : 'Cannot delete entries.',
  ));

  return (
    <Stack direction="row" spacing={1} justifyContent="end">
      {icons.map((icon, i) => <React.Fragment key={i}>{icon}</React.Fragment>)}
    </Stack>
  );
};

const PermissionList: React.FC<IProps> = ({ permissions, onSelect, isLoading }) => {
  const [selectedPermission, setSelectedPermission] = useState<ApiPermission>();

  const onSelectPermission = (permission: ApiPermission) => {
    setSelectedPermission(permission);
    if (onSelect) onSelect(permission);
  };

  if (isLoading) {
    return (
      <TableContainer component={Paper}>
        <LoadingBackdrop />
      </TableContainer>
    );
  }

  if (permissions.length === 0) {
    <Typography>This journal is not shared.</Typography>;
  }

  return (
    <TableContainer component={Paper} sx={{ width: '100%' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell align="right">Permissions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {permissions.map((permission) => (
            <TableRow
              key={permission.id}
              selected={selectedPermission?.id === permission.id}
              onClick={() => onSelectPermission(permission)}
              sx={{ cursor: 'pointer' }}
            >
              <TableCell component="td" scope="row">
                <Stack direction="row" spacing={1} alignItems="center">
                  <UserAvatar user={permission.user} />
                  <Stack>
                    <Typography component="div">{permission.user.name}</Typography>
                    <Hidden smDown>
                      <Typography component="div" variant="caption">{permission.user.email}</Typography>
                    </Hidden>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell>{renderPermissionIcons(permission)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PermissionList;
