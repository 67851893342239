import { createTheme } from '@mui/material/styles';
import { teal, indigo, grey } from '@mui/material/colors';
import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';

const JOURNALS_THEME: Theme = createTheme({
  palette: {
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: teal[500],
    },
    loading: {
      main: grey[300],
    },
  },
});

const baseRem = 1;
const getFontSize = (h: number): string => `${baseRem + 1 - 0.2 * h}rem`;

// Working version for typescript. //TODO ask: show
// https://bobbyhadz.com/blog/typescript-access-object-property-dynamically
type TypographyKey = keyof typeof JOURNALS_THEME.typography;
Array.from(Array(6).keys()).forEach((i) => {
  const h = JOURNALS_THEME.typography[`h${i + 1}` as TypographyKey] as CSSProperties;
  h.fontSize = getFontSize(i + 1);
});

const JOURNALS_THEME_DARK: Theme = createTheme({
  ...JOURNALS_THEME,
  palette: {
    mode: 'dark',
    primary: {
      main: indigo[500],
    },
    secondary: {
      main: teal[500],
    },
    text: {
      primary: '#fff',
      secondary: '#fff',
    },
  },
});

Array.from(Array(6).keys()).forEach((i) => {
  const hd = JOURNALS_THEME_DARK.typography[`h${i + 1}` as TypographyKey] as CSSProperties;
  hd.color = JOURNALS_THEME_DARK.palette.text.primary;
});

// Workaround
// JOURNALS_THEME.typography.h1.fontSize = getFontSize(1);
// JOURNALS_THEME.typography.h2.fontSize = getFontSize(2);
// JOURNALS_THEME.typography.h3.fontSize = getFontSize(3);
// JOURNALS_THEME.typography.h4.fontSize = getFontSize(4);
// JOURNALS_THEME.typography.h5.fontSize = getFontSize(5);
// JOURNALS_THEME.typography.h6.fontSize = getFontSize(6);

// JS version
// Array.from(Array(6).keys()).forEach((i) => {
//     const h = `h${i + 1}`;
//     journalsTheme.typography[h].fontSize = `${baseRem + 1 - 0.2 * i}rem`;
// });

export { JOURNALS_THEME, JOURNALS_THEME_DARK };
