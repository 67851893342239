import { ChangeType, VersionChanges } from './types';

const VERSIONING: {currentVersion:string, currentDisplayVersion: string, versionHistory: VersionChanges[]} = {
  currentVersion: '1.3.2',
  currentDisplayVersion: 'Journals v1.3.2',
  versionHistory: [
    {
      version: 'v1.3.2',
      releaseDate: '01 May 2022',
      changes: [
        {
          type: ChangeType.Added,
          text: 'Added bullets with heading in editor.',
        },
      ],
    },
    {
      version: 'v1.3.1',
      releaseDate: '30 Apr 2022',
      changes: [
        {
          type: ChangeType.Updated,
          text: 'Improved UI of media popup.',
        },
        {
          type: ChangeType.Fixed,
          text: 'Fixed crash in attachment media popup when media attachments were mixed with non media attachments.',
        },
        {
          type: ChangeType.Fixed,
          text: 'Fixed issue where clicking on an attachment would not open the attachment.',
        },
      ],
    },
    {
      version: 'v1.3.0',
      releaseDate: '29 Apr 2022',
      changes: [
        {
          type: ChangeType.Updated,
          text: 'Overhauled media storage to improve performance.',
        },
      ],
    },
    {
      version: 'v1.2.0',
      releaseDate: '22 Apr 2022',
      changes: [
        {
          type: ChangeType.Added,
          text: 'Added the ability to share your journal with others and set permissions to other users.',
        },
        {
          type: ChangeType.Added,
          text: 'Added version history.',
        },
        {
          type: ChangeType.Added,
          text: 'Added list of users who have access to journal in journal list.',
        },
        {
          type: ChangeType.Updated,
          text: 'Changed tooltip for journal avatars in entry list.',
        },
        {
          type: ChangeType.Fixed,
          text: 'Fixed wrong display in popup when cropping journal avatars.',
        },
        {
          type: ChangeType.Fixed,
          text: 'Fixed issue where you could not upload avatar twice.',
        },
      ],
    },
    {
      version: 'v1.1.0',
      releaseDate: '17 Apr 2022',
      changes: [
        {
          type: ChangeType.Added,
          text: 'Added next and previous buttons when seeing attachments for media attachments.',
        },
        {
          type: ChangeType.Updated,
          text: 'Pop-ups such as entry details and filtering now show as full screen on small devices.',
        },
        {
          type: ChangeType.Fixed,
          text: 'Fixed incorrect size for attachment videos.',
        },
      ],
    },
  ],
};

export default VERSIONING;
