import moment from 'moment';
import { IEntriesFilter } from '../types';
import { IDateRange } from '../types/entries-filter';

const MIN_YEAR_OPTION = 2019;
const currentYear = moment().year();

const DEFAULT_DATE_RANGE: IDateRange = {
  minMonth: '01',
  minYear: MIN_YEAR_OPTION,
  maxMonth: '12',
  maxYear: currentYear + 3,
};

const DEFAULT_FILTER: IEntriesFilter = {
  journalIds: '',
  dateRange: DEFAULT_DATE_RANGE,
  tags: '',
  searchText: '',
  searchInContent: false,
};

const MONTH_OPTIONS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const YEAR_OPTIONS = Array.from(Array(currentYear + 3 - MIN_YEAR_OPTION + 1).keys()).map((x) => x + MIN_YEAR_OPTION);

export {
  MONTH_OPTIONS, YEAR_OPTIONS, DEFAULT_FILTER,
};
