import AddIcon from '@mui/icons-material/Add';
import {
  Container,
  Hidden,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { DataList, JournalAvatar, UserAvatar } from 'components';
import { DataListMenuItem } from 'components/data-list/types';
import useApiFetch from 'hooks/use-api-fetch';
import usePermissions from 'hooks/use-permissions';
import { DEFAULT_ICONS, JOURNALS_ROUTE_COLLECTION } from 'journals-constants';
import { getApiUrl } from 'utils/api-utils';
import { ApiEndpoint } from 'enums/api';
import { IUser } from 'types/context';
import { JournalsContext } from 'context/JournalsContext';
import { generateRouteWithId } from 'utils/route-utils';
import { ApiJournal } from '../../../../types/api';

const JournalList: React.FC = () => {
  const {
    isLoading,
    error,
    data: journals,
    refreshData,
  } = useApiFetch(getApiUrl(ApiEndpoint.Journal, undefined, 'detailed=true'), true);
  const { data: deletedJournal, apiDelete } = useApiFetch();
  const {
    isEntryCreateAllowed,
    isJournalEditAllowed,
    isJournalDeleteAllowed,
  } = usePermissions();
  const navigate = useNavigate();
  const { user: currentUser } = useContext(JournalsContext);

  useEffect(() => {
    if (deletedJournal) refreshData();
  }, [deletedJournal]);

  const additionalMenuItems: DataListMenuItem[] = [
    {
      icon: <AddIcon fontSize="small" />,
      text: 'Create Entry',
      actionFn: (entityId: string) => {
        navigate({
          pathname: JOURNALS_ROUTE_COLLECTION.NewEntry.path,
          search: `?${createSearchParams({ journalId: entityId })}`,
        });
      },
    },
    {
      icon: <DEFAULT_ICONS.Share fontSize="small" />,
      text: 'Sharing & Permissions',
      actionFn: (entityId: string) => {
        navigate({
          pathname: generateRouteWithId(JOURNALS_ROUTE_COLLECTION.EditJournalPermissions, entityId),
        });
      },
    },
  ];

  const additionalMenuItemsEnabledFn = (
    journal: ApiJournal,
  ): { [name: string]: boolean } => {
    const retObject: { [name: string]: boolean } = {};
    retObject[additionalMenuItems[0].text] = isEntryCreateAllowed(journal);
    retObject[additionalMenuItems[1].text] = Boolean(journal.owner) && journal.owner?.email === currentUser?.email;
    return retObject;
  };

  const showMenu = (journal: ApiJournal): boolean => isJournalEditAllowed(journal)
        || isJournalDeleteAllowed(journal)
        || isEntryCreateAllowed(journal);

  const getSharedUsers = (journal: ApiJournal): IUser[] => {
    if (!journal.permissions) return [];
    return journal.permissions.filter((permission) => permission.user.email !== currentUser?.email)
      .map((permission) => permission.user);
  };

  const renderListItem = (
    journal: ApiJournal,
    menuAction: React.ReactElement,
  ): React.ReactElement => (
    <React.Fragment key={journal.id}>
      <ListItem
        sx={{
          border: `solid 3px ${journal.colour}`,
          boxShadow: (theme) => theme.shadows[5],
          my: 2,
        }}
        secondaryAction={showMenu(journal) && menuAction}
      >
        <ListItemAvatar sx={{ mr: 1 }}>
          <JournalAvatar journal={journal} />
        </ListItemAvatar>
        <Stack direction="row" justifyContent="space-between" flexGrow={1}>
          <ListItemText
            primary={journal.title}
            secondary={(
              <Link
                underline="none"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(
                  `${JOURNALS_ROUTE_COLLECTION.EntryList.path}?journalIds=${journal.id}`,
                )}
              >
                {`Contains ${journal.entriesCount} entries`}
              </Link>
          )}
          />
          <Hidden smDown>
            {getSharedUsers(journal).length > 0
            && (
            <Stack direction="row" alignItems="center" sx={{ mr: 2 }}>
              <Tooltip title="This journal is shared with other users.">
                <DEFAULT_ICONS.Shared color="secondary" sx={{ mr: 1 }} />
              </Tooltip>
              {getSharedUsers(journal).map((user) => (
                <UserAvatar key={user.email} user={user} small />
              ))}

            </Stack>
            )}
          </Hidden>
        </Stack>
      </ListItem>
    </React.Fragment>
  );

  const dataList: React.ReactElement = (
    <DataList
      dataList={journals as ApiJournal[]}
      isLoading={isLoading}
      error={error}
      entitySingular="Journal"
      entityPlural="Journals"
      newEntityRoute={JOURNALS_ROUTE_COLLECTION.NewJournal}
      editEntityRoute={JOURNALS_ROUTE_COLLECTION.EditJournal}
      apiDelete={apiDelete}
      apiDeleteEndpoint="/journal"
      additionalMenuItems={additionalMenuItems}
      additionalMenuItemsEnabledFn={additionalMenuItemsEnabledFn}
      menuEditEnabledFn={isJournalEditAllowed}
      menuDeleteEnabledFn={isJournalDeleteAllowed}
      renderListItem={renderListItem}
    />
  );

  return <Container maxWidth="md">{dataList}</Container>;
};

export default JournalList;
