import { useReducer, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import {
  apiDeleteFn,
  ApiFetchActionType, apiGetFn, apiPostFn, apiPutFn, IApiFetch, IApiFetchOptions, IApiFetchState, refreshDataFn,
} from './types';
import reducer from './reducer';

const initialState: IApiFetchState = {
  data: undefined,
  isLoading: false,
  error: undefined,
};

const useApiFetch = (
  endpoint: string = '',
  getOnLoad: boolean = false,
): IApiFetch => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getAccessTokenSilently } = useAuth0();
  const { apiBaseUrl } = window.config;

  /**
     * Gets the access token from Auth0.
     * @returns The access token.
     */
  const getAccessToken = async (): Promise<string> => getAccessTokenSilently({
    audience: 'https://api.journal.unclebenportal.net',
    scope: 'super',
  });

  const apiFetch = async (
    endpointInput: string,
    method: string,
    bodyInput: unknown,
    returnResponseObject: boolean,
    fetchOpts: IApiFetchOptions = {},
  ): Promise<Response | undefined> => {
    const accessToken: string = await getAccessToken();
    let payload: unknown;

    dispatch({ type: ApiFetchActionType.StartRequest });
    try {
      let body:unknown = bodyInput;
      const headers: HeadersInit = {
        Authorization: `Bearer ${accessToken}`,
      };
      if (bodyInput != null // == null is equivalent to === null || === undefined
          && typeof bodyInput === 'object'
          && !(bodyInput instanceof FormData)) {
        // is JSON object
        headers['Content-Type'] = 'application/json';
        body = JSON.stringify(bodyInput);
      }
      const response: Response = await fetch(`${apiBaseUrl}${endpointInput}`, {
        ...fetchOpts,
        method,
        headers,
        body: body as BodyInit,
      });

      if (!response.ok) throw await response.text();

      payload = returnResponseObject ? response : await response.json();
      dispatch({ type: ApiFetchActionType.RequestSuccess, payload });
      return response;
    } catch (e) {
      if (typeof e === 'string') {
        payload = e === '' ? 'Error during request' : e;
        dispatch({
          type: ApiFetchActionType.RequestError,
          payload,
        });
      } else if (!(e instanceof Error && e.name === 'AbortError')) {
        throw e;
      }
    }

    return undefined;
  };

  const apiGet: apiGetFn = async (
    endpointInput,
    fetchOpts?,
  ) => apiFetch(endpointInput, 'get', undefined, false, fetchOpts);

  const apiPost: apiPostFn = async (
    endpointInput,
    body,
    returnResponseObject = false,
  ) => apiFetch(endpointInput, 'post', body, returnResponseObject);

  const apiPut: apiPutFn = async (
    endpointInput,
    id,
    body,
    returnResponseObject = false,
  ) => apiFetch(
    `${endpointInput}/${id}`,
    'put',
    body,
    returnResponseObject,
  );

  const apiDelete: apiDeleteFn = async (
    endpointInput,
    id,
    returnResponseObject = false,
  ) => apiFetch(
    `${endpointInput}/${id}`,
    'delete',
    null,
    returnResponseObject,
  );

  const refreshData: refreshDataFn = async () => apiGet(endpoint);

  useEffect(() => {
    if (getOnLoad) {
      const abortCtrl = new AbortController();
      const opts: IApiFetchOptions = { abortSignal: abortCtrl.signal };
      apiGet(endpoint, opts);

      return () => abortCtrl.abort();
    }

    return undefined;
    // in this case, this is intended to run only on startup of hook.
  }, []);

  return {
    ...state, apiGet, apiPost, apiPut, apiDelete, refreshData,
  };
};

export default useApiFetch;
