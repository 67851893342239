import {
  Divider, FormLabel, MenuItem, Select, SelectChangeEvent, Stack,
} from '@mui/material';
import React from 'react';
import { MONTH_OPTIONS, YEAR_OPTIONS } from '../../constants';
import { IDateRange } from '../../types/entries-filter';

interface IProps{
    dateRange: IDateRange,
    onChange: (dateRange: IDateRange) => void
}

const EntriesFilterDateRange: React.FC<IProps> = ({
  dateRange, onChange,
}) => {
  const onMinMonthChange = (e: SelectChangeEvent<string>) => {
    onChange({ ...dateRange, minMonth: e.target.value });
  };

  const onMinYearChange = (e: SelectChangeEvent<number>) => {
    onChange({ ...dateRange, minYear: (e.target.value as number) });
  };

  const onMaxMonthChange = (e: SelectChangeEvent<string>) => {
    onChange({ ...dateRange, maxMonth: e.target.value });
  };

  const onMaxYearChange = (e: SelectChangeEvent<number>) => {
    onChange({ ...dateRange, maxYear: (e.target.value as number) });
  };

  return (
    <>
      <Divider>Date Range</Divider>
      <Stack direction="row" gap={1} alignItems="center">
        <FormLabel sx={{ minWidth: '40px', textAlign: 'right' }}>From</FormLabel>
        <Select value={dateRange.minMonth} onChange={onMinMonthChange} sx={{ flexGrow: '1' }}>
          {MONTH_OPTIONS.map((month, index) => (
            <MenuItem key={month} value={(`0${index + 1}`).slice(-2)}>
              {month}
            </MenuItem>
          ))}
        </Select>
        <Select value={dateRange.minYear} onChange={onMinYearChange}>
          {YEAR_OPTIONS.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      <Stack direction="row" gap={1} alignItems="center">
        <FormLabel sx={{ minWidth: '40px', textAlign: 'right' }}>To</FormLabel>
        <Select value={dateRange.maxMonth} onChange={onMaxMonthChange} sx={{ flexGrow: '1' }}>
          {MONTH_OPTIONS.map((month, index) => (
            <MenuItem key={month} value={(`0${index + 1}`).slice(-2)}>
              {month}
            </MenuItem>
          ))}
        </Select>
        <Select value={dateRange.maxYear} onChange={onMaxYearChange}>
          {YEAR_OPTIONS.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </>
  );
};

export default EntriesFilterDateRange;
