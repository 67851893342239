import {
  NavigateBefore, Edit, Delete, Share, People,
} from '@mui/icons-material';

const DEFAULT_ICONS = {
  GoBack: NavigateBefore,
  Edit,
  Delete,
  Share,
  Shared: People,
};

export default DEFAULT_ICONS;
