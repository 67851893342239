import {
  Alert, AlertTitle, Button, Container, Divider, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { LoadingBackdrop } from 'components';
import { ApiEndpoint } from 'enums/api';
import useApiFetch from 'hooks/use-api-fetch';
import { DEFAULT_ICONS, JOURNALS_ROUTE_COLLECTION } from 'journals-constants';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiJournal, ApiPermission, SavingStatus } from 'types/api';
import { getApiUrl } from 'utils/api-utils';
import { generateRouteWithId } from 'utils/route-utils';
import { JournalsContext } from 'context/JournalsContext';
import PermissionForm from './PermissionForm';
import PermissionList from './PermissionList';

const EditJournalPermissions = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useContext(JournalsContext);

  const goToJournalList = (): void => {
    navigate(JOURNALS_ROUTE_COLLECTION.EditJournal.path);
  };

  if (!params.journalId) {
    goToJournalList();
  }

  const theme = useTheme();
  const isLargeVersion = useMediaQuery(theme.breakpoints.up('lg'), {
    noSsr: true,
  });

  const {
    data, isLoading, error, apiGet,
  } = useApiFetch(getApiUrl(ApiEndpoint.Journal, params.journalId), true);
  const journal = data as ApiJournal;

  const [savingStatus, setSavingStatus] = useState<SavingStatus>(SavingStatus.NotSaved);
  const [selectedPermission, setSelectedPermission] = useState<ApiPermission>();

  useEffect(() => {
    if (savingStatus === SavingStatus.Saved) {
      apiGet(getApiUrl(ApiEndpoint.Journal, params.journalId));
      setSelectedPermission(undefined);
    }
  }, [savingStatus]);

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        There was an error getting the journal.
      </Alert>
    );
  }

  if (isLoading || !journal) {
    return <LoadingBackdrop />;
  }

  return (
    <Container maxWidth="lg" sx={{ p: 0 }}>
      <Stack spacing={2}>
        <Typography variant="h4">
          {'Editing permissions for '}
          <strong>
            {journal.title}
          </strong>
        </Typography>
        <Divider />
        <Stack direction={isLargeVersion ? 'row' : 'column'} spacing={2}>
          <PermissionList
            permissions={journal?.permissions?.filter((x) => x.user.email !== currentUser?.email) ?? []}
            onSelect={setSelectedPermission}
            isLoading={savingStatus === SavingStatus.Saving}
          />
          <PermissionForm
            journalId={journal.id!}
            permission={selectedPermission}
            onSavingStatusUpdate={setSavingStatus}
          />
        </Stack>
        <Stack alignItems="start">
          <Button
            onClick={() => navigate(generateRouteWithId(JOURNALS_ROUTE_COLLECTION.EditJournal, journal.id!))}
            startIcon={<DEFAULT_ICONS.Edit />}
          >
            Edit Journal
          </Button>
          <Button onClick={() => navigate(JOURNALS_ROUTE_COLLECTION.JournalList.path)} startIcon={<DEFAULT_ICONS.GoBack />}>
            Back to Journals
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default EditJournalPermissions;
