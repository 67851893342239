import { Avatar, styled, Tooltip } from '@mui/material';
import React from 'react';
import { IUser } from '../../types/context';

interface IProps {
    user: IUser;
    small?: boolean;
}

const UserAvatar: React.FC<IProps> = ({ user, small = false }): JSX.Element => {
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 20,
    height: 20,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

  if (!user) return <> </>;

  if (small) {
    return (
      <Tooltip title={user.name}>
        <SmallAvatar alt={user.name} src={user.avatar} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title={user.name}>
      <Avatar
        src={user.avatar}
        alt={user.name}
        sx={{
          border: (theme) => `2px solid ${theme.palette.background.paper}`,
        }}
      />
    </Tooltip>
  );
};

export default UserAvatar;
