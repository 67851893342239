import React, {
  useEffect, useCallback, useRef, useState,
} from 'react';

import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Stack } from '@mui/material';
import { getCroppedImgBlob, translateAspect } from 'utils/image-utils';
import { AppModal } from 'components';

interface IProps{
    uploadedFile: File,
    open: boolean,
    circularCrop?: boolean
    aspect?: 'square'|'rectangle'|'freeform',
    width?: string, // e.g. 30% or 30px
    height?: string,
    onCropComplete: (file: File) => void
}

const getDefaultCrop = (width?:string, height?: string):Crop => {
  const parsedWidth: {unit: 'px'|'%', width:number} = { unit: '%', width: 50 };
  const parsedHeight: {unit: 'px'|'%', height:number} = { unit: '%', height: 50 };

  if (width !== undefined) {
    const match = width.match(/^(\d*)(%|px)$/);
    if (!match) throw new Error('Incorrect parameter width. Must be a number followed by % or px');

    // eslint-disable-next-line prefer-destructuring
    parsedWidth.unit = match[2] as 'px'|'%';
    parsedWidth.width = Number(match[1]);
  }

  if (height !== undefined) {
    const match = height.match(/^(\d*)(%|px)$/);
    if (!match) throw new Error('Incorrect parameter height. Must be a number followed by % or px');

    // eslint-disable-next-line prefer-destructuring
    parsedHeight.unit = match[2] as 'px'|'%';
    parsedHeight.height = Number(match[1]);
  }

  return {
    unit: parsedWidth.unit,
    width: parsedWidth.width,
    height: parsedHeight.height,
    x: 0,
    y: 0,
  };
};

const CropImageModal : React.FC<IProps> = ({
  uploadedFile, open, onCropComplete, circularCrop = false, aspect = 'square', width = '200px', height = '200px',
}) => {
  const imgRef = useRef<HTMLImageElement|undefined>();
  const [upImg, setUpImg] = useState<string>('');

  const [crop, setCrop] = useState<Crop>(getDefaultCrop(width, height));

  useEffect(() => {
    if (!uploadedFile) return;

    const reader = new FileReader();
    reader.addEventListener('load', () => setUpImg(reader.result as string));
    reader.readAsDataURL(uploadedFile);
  }, [uploadedFile]);

  const onLoad = useCallback((img: HTMLImageElement) => {
    imgRef.current = img;
  }, []);

  const onCropChange = (c:Crop):void => {
    setCrop(c);
  };

  const onClose = async () => {
    const image = imgRef.current;
    if (!image) throw new Error('imageRef.current is undefined');

    const croppedImageBlob = await getCroppedImgBlob(image, crop);
    const file = new File([croppedImageBlob], uploadedFile.name, {
      type: uploadedFile.type,
    });
    if (onCropComplete) onCropComplete(file);
  };

  return (
    <AppModal open={open} title="Crop image" onClose={onClose} contentSx={{ justifyContent: 'center', alignItems: 'center' }}>
      <Stack>
        <ReactCrop
          circularCrop={circularCrop}
          aspect={translateAspect(aspect)}
          crop={crop}
          onChange={(c) => onCropChange(c)}
        >
          <img src={upImg} onLoad={(e) => onLoad(e.target as HTMLImageElement)} alt="Img to crop" />
        </ReactCrop>
      </Stack>
    </AppModal>
  );
};

export default CropImageModal;
