/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Avatar, Button, Container, Input, Stack, TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ColorPicker } from 'material-ui-color';
import { CropImageModal } from 'components';
import useApiFetch from 'hooks/use-api-fetch';
import { DEFAULT_ICONS, JOURNALS_ROUTE_COLLECTION } from 'journals-constants';
import { ApiEndpoint } from 'enums/api';
import { generateRouteWithId } from 'utils/route-utils';
import { ApiJournal } from '../../../../types/api';

const JournalForm: React.FC<{journal?: ApiJournal}> = ({ journal }) => {
  const defaultTitle:string = 'Untitled Journal';
  const [journalId] = useState<string|undefined>(journal ? journal.id : undefined);
  const [title, setTitle] = useState<string>(journal ? journal.title : defaultTitle);
  const [colour, setColour] = useState<string>(journal ? journal.colour : '#BDBDBD');
  const [avatar, setAvatar] = useState<string>(journal ? journal.avatar : '');
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [croppingImageFile, setCroppingImageFile] = useState<File|undefined>();

  const {
    isLoading: isUploading,
    error: uploadingError,
    data: uploadedResponse,
    apiPost: apiUploadFile,
  } = useApiFetch();

  const {
    isLoading: isSaving,
    error: savingError,
    data: savedData,
    apiPost: apiPostJournal,
    apiPut: apiPutJournal,
  } = useApiFetch();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setIsFormValid(title !== '');
  }, [title]);

  useEffect(() => {
    if (uploadingError === undefined && uploadedResponse !== undefined) {
      setAvatar((uploadedResponse as Response).headers.get('location') as string);
    }
  }, [uploadingError, uploadedResponse]);

  useEffect(() => {
    if (savingError) {
      enqueueSnackbar('Error while saving.', { variant: 'error' });
      return;
    }
    if (savedData) {
      enqueueSnackbar('Journal saved.', { variant: 'success' });
      navigate(JOURNALS_ROUTE_COLLECTION.JournalList.path);
    }
  }, [isSaving, savingError, savedData]);

  const onTitleInputClick = (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    const el = e.target as HTMLInputElement;
    if (el.value === defaultTitle) el.select();
  };

  const saveFile = async (e: React.ChangeEvent<HTMLElement>): Promise<void> => {
    const elem = e.target as HTMLInputElement;
    const file: File|undefined = elem.files ? elem.files[0] : undefined;
    if (!file) return;

    if (!file.type.startsWith('image')) {
      enqueueSnackbar('File uploaded is not an image.', { variant: 'error' });
      return;
    }

    setCroppingImageFile(file);
  };

  const saveJournal = async (): Promise<void> => {
    const dataToSend: ApiJournal = {
      title,
      colour,
      avatar,
    };
    if (!journalId) {
      // POST
      await apiPostJournal(ApiEndpoint.Journal, dataToSend);
    } else {
      // PUT
      await apiPutJournal(ApiEndpoint.Journal, journalId, dataToSend);
    }
  };

  const onCropComplete = async (file: File): Promise<void> => {
    setCroppingImageFile(undefined);
    const formData = new FormData();
    formData.append('formFile', file);
    formData.append('fileType', file.type);
    formData.append('fileName', file.name);
    await apiUploadFile(ApiEndpoint.UploadAvatar, formData, true);
  };

  const stringAvatar = (nameInput:string) => {
    const name = nameInput ?? defaultTitle;

    return {
      sx: {
        bgcolor: colour,
      },
      children: name.substring(0, Math.min(name.length, 2)).toUpperCase(),
    };
  };

  return (
    <>
      {croppingImageFile && (
        <CropImageModal
          uploadedFile={croppingImageFile}
          open
          onCropComplete={onCropComplete}
          circularCrop
          aspect="square"
        />
      )}
      <Container maxWidth="xs">
        <Stack spacing={5}>
          <TextField
            label="Title"
            fullWidth
            required
            defaultValue={title}
            onChange={(e) => setTitle(e.target.value)}
            onClick={onTitleInputClick}
            error={!isFormValid}
          />

          <Stack direction="row">
            <div style={{ flexGrow: 1 }}>
              <TextField label="Colour" required fullWidth value={colour} disabled />
            </div>
            <div>
              <ColorPicker
                value={colour}
                hideTextfield
                deferred
                onChange={(changedColour) => setColour(`#${changedColour.hex}`)}
              />
            </div>
          </Stack>

          <Stack direction="row" spacing={5} sx={{ alignContent: 'center' }}>
            {!avatar && <Avatar {...stringAvatar(title)} sx={{ width: 100, height: 100 }} />}
            {avatar && (
            <Avatar
              alt={title}
              src={avatar === '' ? '/images/imagePlaceholder.jpg' : avatar}
              sx={{ width: 100, height: 100, border: `solid 3px ${colour}` }}
            />
            )}

            <label htmlFor="contained-button-file">
              <Stack direction="column" sx={{ justifyContent: 'center', height: '100%' }}>
                <Input
                  sx={{ display: 'none' }}
                  inputProps={{ accept: 'image/*' }}
                  id="contained-button-file"
                  type="file"
                  onClick={(e) => { (e.target as HTMLInputElement).value = ''; }}
                  onChange={saveFile}
                  disabled={isUploading}
                />

                <LoadingButton
                  variant="outlined"
                  component="span"
                  loading={isUploading}
                  endIcon={<UploadIcon />}
                  loadingPosition="end"
                >
                  Upload Avatar
                </LoadingButton>
              </Stack>
            </label>
          </Stack>

          <Stack direction="column" spacing={2}>
            <LoadingButton
              size="large"
              variant="contained"
              onClick={saveJournal}
              disabled={!isFormValid}
              loading={isSaving}
              endIcon={<SaveIcon />}
              loadingPosition="end"
            >
              {journalId === undefined ? 'Create' : 'Update'}
              {' '}
              Journal
            </LoadingButton>
          </Stack>

          <Stack alignItems="start">
            {journalId !== undefined
              && (
              <Button
                onClick={() => navigate(generateRouteWithId(JOURNALS_ROUTE_COLLECTION.EditJournalPermissions, journalId))}
                startIcon={<DEFAULT_ICONS.Share />}
              >
                Sharing and Permissions
              </Button>
              )}
            <Button onClick={() => navigate(JOURNALS_ROUTE_COLLECTION.JournalList.path)} startIcon={<DEFAULT_ICONS.GoBack />}>
              Back to Journals
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default JournalForm;
