import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';

export interface IProps {
    open: boolean;
    title: string;
    message: string;
    confirmText?: string;
    isDangerConfirm?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}

const ConfirmDialog: React.FC<IProps> = ({
  open,
  title,
  message,
  confirmText,
  isDangerConfirm,
  onConfirm,
  onCancel,
}) => (
  <Dialog maxWidth="xs" open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{message}</DialogContent>
    <DialogActions>
      <Button variant="outlined" autoFocus onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant="contained"
        onClick={onConfirm}
        color={isDangerConfirm ? 'error' : 'primary'}
      >
        {confirmText || 'Ok'}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
